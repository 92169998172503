import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import useSWRMutation from 'swr/mutation';
import { z } from 'zod';

import { mutation } from '../../1_shared/api/apiInstance';
import { ERequestType } from '../../1_shared/api/interfaces/ERequestType';
import LogoSvg from '../../1_shared/assets/pictures/logo.svg';
import LogoMobileSvg from '../../1_shared/assets/pictures/logo-mobile.svg';
import LogoMIPSvg from '../../1_shared/assets/pictures/MIPv2.svg';
import { ERoles } from '../../1_shared/config/enums/ERoles';
import { RoutePath } from '../../1_shared/config/routes';
import { UserRoutes } from '../../1_shared/constants/userRoutes';
import { Page } from '../../1_shared/ui';

import styles from './CheckSession.module.scss';

const checkSessionInput = z.object({
  uuid: z.string().nullable().optional(),
});

const checkSessionOutput = z.object({
  id: z.string(),
  slotId: z.string(),
  startTime: z.string(),
  status: z.string(),
});

type TCheckSessionInput = z.infer<typeof checkSessionInput>;
type TCheckSessionOutput = z.infer<typeof checkSessionOutput>;

export const checkSessionObject = mutation<
  TCheckSessionInput,
  TCheckSessionOutput
>(ERequestType.Get);

const CheckSession = () => {
  const role = localStorage.getItem('role') || '';

  const navigate = useNavigate();
  const [percent, setPercent] = useState(-50);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [auto] = useState(false);
  const mergedPercent = auto ? 'auto' : percent;

  const location = useLocation();
  const uuid = location?.pathname?.split('/')?.[2] ?? null;

  const { data, trigger } = useSWRMutation(
    `/ss/v2/sessions/${uuid}/info`,
    checkSessionObject,
    {
      onError: () => {
        if (role) return navigate(UserRoutes[role as ERoles]);
        return navigate('/');
      },
    },
  );

  useEffect(() => {
    if (uuid && role) {
      (async () => {
        await trigger({ uuid });
      })();
    }
  }, [uuid]);

  useEffect(() => {
    if (data) {
      const getData = checkSessionOutput.safeParse(data).data;
      if (
        dayjs().diff(dayjs(getData?.startTime), 'minutes') > 5 ||
        dayjs(getData?.startTime).diff(dayjs(), 'minutes') < 5
      ) {
        return navigate(`${RoutePath.CONFERENCE}/${getData?.slotId}`);
      }
      return navigate(UserRoutes[role as ERoles]);
    }
    return undefined;
  }, [data]);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setPercent(v => {
        const nextPercent = v + 2;
        return nextPercent > 150 ? -50 : nextPercent;
      });
    }, 100);
    return () => clearTimeout(timerRef.current);
  }, [percent]);

  useEffect(() => {
    if (role === ERoles.Unauthorized || !role) navigate('/login');
  });

  return (
    <Page>
      <div className={styles.root}>
        <div>
          <div className={styles.wrapperLogos}>
            <Link to={RoutePath.MAIN}>
              <source media="(max-width: 1023px)" srcSet={LogoMobileSvg} />
              <img src={LogoSvg} alt="DoTherapy" width="200" height="39" />
            </Link>
            <a
              href="https://inpsycho.ru"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.mipLogo}
            >
              <source media="(max-width: 1023px)" srcSet={LogoMIPSvg} />
              <img src={LogoMIPSvg} alt="DoTherapy" width="200" height="39" />
            </a>
          </div>
        </div>
        <div className={styles.wrapper}>
          <Spin percent={auto ? 'auto' : mergedPercent} size="large" />
          Идет проверка доступности сесии...
        </div>
      </div>
    </Page>
  );
};

export default CheckSession;
