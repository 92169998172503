export const enum EYandexInputTypes {
  InText = 'in_text',
  Btn = 'btn',
}

export const enum EYandexTypes {
  Click = 'click',
  Send = 'send',
}

export const enum EYandexPage {
  Catalog = 'catalog',
  Spec = 'spec',
  Order = 'order',
}
