import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Spin } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

import ProfileUser from '1_shared/assets/images/UserProfilePast.svg';

import { RoutePath } from '../../../1_shared/config/routes';
import {
  Button,
  Content,
  Input,
  Select,
  Typography,
} from '../../../1_shared/ui';
import { SpecialistAvatar } from '../../../2_entities';
import ModalFeedback from '../../ModalFeedback/ui/ModalFeedback';
import { IProfileSession } from '../../SessionsPc/api/interfaces/IProfileSession';
import { EPeriod } from '../api/interfaces/EPeriod';
import { IForm } from '../api/interfaces/IForm';
import { periodConfig } from '../config/periodConfig';
import useGetSessionHistory from '../model/useGetSessionHistory';

import styles from './SpecialistSessionsTable.module.scss';

interface ISpecialistSessionsTable {
  withSearch?: boolean;
  withNavigateBtn?: boolean;
}

const SpecialistSessionsTable: React.FC<ISpecialistSessionsTable> = ({
  withSearch = true,
  withNavigateBtn = false,
}) => {
  // modal
  const [open, setOpen] = useState<{
    openModal: boolean;
    session: IProfileSession | null;
  }>({
    openModal: false,
    session: null,
  });

  const { control, handleSubmit, watch, getValues } = useForm<IForm>({
    values: {
      period: EPeriod.Month,
    },
  });

  watch(['period', 'date']);

  const openModal = (session: IProfileSession) => {
    setOpen({ session, openModal: true });
  };
  const onClose = () => {
    setOpen(prev => ({ ...prev, openModal: false }));
  };

  const { data, isLoading, changeFilters, total, goNext } =
    useGetSessionHistory();

  const onSubmit = (data: IForm) => {
    changeFilters(data);
  };

  const debouncedSubmit = useCallback(
    debounce(handleSubmit(onSubmit), 800),
    [],
  );

  const handleSubmitDebounce = (onChange: (val: any) => any, value: any) => {
    onChange(value);
    debouncedSubmit();
  };

  const handleSubmitDefault = async (
    onChange: (val: any) => any,
    value: any,
  ) => {
    onChange(value || null);
    await handleSubmit(onSubmit)();
  };

  // customWeekStartEndFormat from antd (уточнить какой нужен будет)
  const formatDate = (date: string) =>
    dayjs(date).locale('ru').format('D MMMM, HH:mm');

  return (
    <section className={styles.root}>
      <Content>
        <form>
          <div className={styles.titleWrapper}>
            <Typography type="title">История сеансов</Typography>
            {withNavigateBtn && (
              <Link
                to={RoutePath.SPECIALIST_CABINET}
                className={styles.navigateToSessionPage}
              >
                <span className={styles.navigateText}>Все сеансы </span>
              </Link>
            )}
          </div>
          {withSearch && (
            <div className={styles.searchWrap}>
              <div className={styles.specialistSearchWrapper}>
                <Controller
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      value={value}
                      onChange={val => {
                        handleSubmitDebounce(onChange, val.target.value);
                      }}
                      prefix={<SearchOutlined style={{ color: '#323232' }} />}
                      placeholder="Искать по имени"
                    />
                  )}
                  name="name"
                />

                <Controller
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker.RangePicker
                      size="middle"
                      disabled={!!getValues('period')}
                      placeholder={['Любая дата', 'Любая дата']}
                      format="DD-MM-YY"
                      value={
                        value
                          ? [
                              dayjs(value?.dateFrom, 'YYYY-MM-DD'),
                              dayjs(value?.dateTo, 'YYYY-MM-DD'),
                            ]
                          : null
                      }
                      onChange={val => {
                        handleSubmitDefault(
                          onChange,
                          val
                            ? {
                                dateFrom: dayjs(val[0]).format('YYYY-MM-DD'),
                                dateTo: dayjs(val[1]).format('YYYY-MM-DD'),
                              }
                            : null,
                        );
                      }}
                    />
                  )}
                  name="date"
                />
              </div>
              <Controller
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={value => {
                      handleSubmitDefault(field.onChange, value);
                    }}
                    disabled={!!getValues('date')}
                    allowClear
                    className={styles.selectPeriod}
                    placeholder="Период"
                    options={periodConfig}
                  />
                )}
                name="period"
              />
            </div>
          )}
        </form>
        <div className={styles.tableWrapper}>
          <table className={styles.tableCont}>
            <thead>
              <tr className={styles.columnsTitle}>
                <th align="left">
                  <Typography type="subtitle">КЛИЕНТ</Typography>
                </th>
                <th align="left">
                  <Typography type="subtitle">ДАТА И ВРЕМЯ</Typography>
                </th>
                <th align="left">
                  <Typography type="subtitle">СТОИМОСТЬ</Typography>
                </th>
                <th align="left">
                  <Typography type="subtitle" />
                </th>
              </tr>
            </thead>
            <tbody className={styles.tbodyItem}>
              {data &&
                data?.map(
                  (
                    session: IProfileSession, // todo: remove
                  ) => (
                    <tr key={session.id} className={styles.item}>
                      <td align="left" className={styles.tdItem}>
                        <SpecialistAvatar
                          imgPreview={ProfileUser}
                          firstName={session.clientNickname}
                        />
                      </td>
                      <td>{formatDate(session.timePoint)}</td>
                      <td>{session.cost}₽</td>
                      <td>
                        <Button
                          onClick={() => openModal(session)}
                          type="secondary"
                        >
                          ОСТАВИТЬ ОБРАТНУЮ СВЯЗЬ
                        </Button>
                      </td>
                    </tr>
                  ),
                )}
            </tbody>
          </table>
        </div>
        {isLoading && (
          <div className={styles.loading}>
            <Spin />
          </div>
        )}
        {!isLoading && data?.length !== total && (
          <div className={styles.btnWrapp}>
            <Button onClick={goNext}>ПОКАЗАТЬ БОЛЬШЕ</Button>
          </div>
        )}
        {open.session && (
          <ModalFeedback
            open={open.openModal}
            onClose={onClose}
            session={open.session}
          />
        )}
      </Content>
    </section>
  );
};

export default SpecialistSessionsTable;
