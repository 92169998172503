import { ReactElement } from 'react';
import { Button } from 'antd';
import cn from 'classnames';

import type { ICustomButtonProps } from '../types/types';

import styles from './CustomButton.module.scss';

export const CustomButton = ({
  className,
  children,
  type = 'primary',
  ...props
}: ICustomButtonProps): ReactElement => {
  const antBtnType = type === 'secondary' ? 'default' : type;
  const classname = cn(styles.btn, className, {
    [styles.secondary]: type === 'secondary',
  });

  return (
    <Button type={antBtnType} className={classname} {...props}>
      {children}
    </Button>
  );
};
