import { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import SpecialistPersonCardData from '2_entities/SpecialistPersonCardData/ui/SpecialistPersonCardData';
import { PreviewListImage } from '3_features';

import { EYandexPage } from '../../../1_shared/api/services/configs/interfaces/EYandexConfigs';
import { ISlot } from '../../../1_shared/config/interfaces/ISlot';
import { RoutePath } from '../../../1_shared/config/routes';
import { configMedia } from '../../SpecialistCard/ui/config/configMedia';

import { ISpecPersonCards } from './interfaces/ISpecPersonCards';

import styles from './SpecialistPersonCard.module.scss';

const SpecialistPersonCard = ({ spec }: ISpecPersonCards): ReactElement => {
  const navigate = useNavigate();

  const onSubmit = (data?: ISlot) => {
    navigate(RoutePath.ORDER, {
      state: { id: spec.id, slot: data || spec.slots[0] },
    });
  };
  const media = useMemo(
    () =>
      spec.mediaContentResponse?.length
        ? spec.mediaContentResponse.sort((a, b) =>
            Number((a as any)?.isPimary) > Number((b as any)?.isPimary)
              ? 0
              : -1,
          )
        : [configMedia],
    [spec?.mediaContentResponse],
  );

  return (
    <div key={spec.id} className={styles.root}>
      <PreviewListImage media={media} />
      <SpecialistPersonCardData
        specialist={spec}
        onChange={onSubmit}
        pageType={EYandexPage.Spec}
      />
    </div>
  );
};

export default SpecialistPersonCard;
