import dayjs from 'dayjs';

const YEAR_DIGITS_COUNT = 4;
const REG_EXP_FOR_DATE = /^\d{4}-\d{2}-\d{2}$/;

/*
 * NOTE: Хелпер нужен для проверки значения года, который приходит как в unix формате,
 * так и в виде строки года
 * TODO: удалить после переноса редактирования на новый бэкенд
 */

export const isEducationYearInUnix = (value: number | string) => {
  // Отсев значений с 4 символами, представляющих год, например 2001 и др, а так же 2001-01-01
  if (
    typeof value === 'string' &&
    (value.length === YEAR_DIGITS_COUNT || REG_EXP_FOR_DATE.test(value))
  ) {
    return false;
  }
  if (value >= 1970 && value <= 9999) {
    return false;
  }

  // для 1970 года
  if (value === 0) {
    return true;
  }

  // Если значение в виде строки
  if (typeof value === 'string') {
    value = parseInt(value, 10);
  }

  // Если значение конечное число
  if (!Number.isFinite(value)) {
    return false;
  }

  const date = dayjs.unix(value);
  return date.isValid();
};
