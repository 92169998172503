import { ReactElement } from 'react';

import { Content, Footer, Page } from '../../../1_shared/ui';
import { ProfileUser } from '../../../2_entities';
import { Header, PcAdminPanel } from '../../../4_widgets';

const PcAdmin = (): ReactElement => (
  <Page>
    <Header />
    <Content>
      <ProfileUser />
    </Content>
    <PcAdminPanel />
    <Footer />
  </Page>
);

export default PcAdmin;
