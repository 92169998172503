import * as yup from 'yup';

import {
  IApplicationForm,
  IOrderFormBySpec,
} from '../ui/interface/IApplicationForm';
// TODO add regexp
// const phoneRegExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;

// TODO: change yup to zod
export const schema = yup.object<IApplicationForm & IOrderFormBySpec>().shape({
  name: yup.string(),
  slotId: yup.string().required(),
  email: yup.string().email(),
  phone: yup.string(),
  code: yup.string(),
  promoCode: yup.string(),
  clientUserId: yup.string().optional(),

  // deprecated by DOT-391
  // agreementTerms: yup.boolean(),
  // agreementDataProcessing: yup.boolean(),
});
