import { ISpecialistByIdData } from '../../../config/interfaces/ISpecialistByIdData';
import { mutation, query } from '../../apiInstance';

import {
  IBookClientRequest,
  IBookClientResponse,
  ISpecialistsClient,
} from './interfaces/specialistService.interface';

export const getSpecById = query<null, ISpecialistByIdData>();

export const specialistsClients = query<null, ISpecialistsClient[]>();

// TODO: create response interface
export const bookClient = mutation<IBookClientRequest, IBookClientResponse>();
