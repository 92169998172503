import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { ISlot } from '1_shared/config/interfaces/ISlot';
import { Select } from '1_shared/ui';

import useCreateOptions from '../model/useCreateOptions';

import { IDataSelectProps } from './interfaces/IDataSelectProps';

import styles from './DateSelect.module.scss';

type IState = {
  slot: ISlot;
};

const SlotSelect = ({
  value,
  onChange,
  disabled,
  spec,
}: IDataSelectProps): ReactElement => {
  const { options } = useCreateOptions(spec?.slots || []);
  const { state } = useLocation();

  return (
    <div className={styles.dateSelect}>
      <Select
        options={options}
        value={options.find(el => el.value === value)}
        onChange={onChange}
        defaultValue={
          (state as IState)?.slot &&
          options.find(el => el.value === (state as IState)?.slot?.slotId)
        }
        disabled={disabled}
      />

      {/* deprecated by DOT-390 */}
      {/* <button className={styles.btn} type='button' onClick={() => onChange(slots?.[0]?.slotId)}> */}
      {/*  <Typography type="description" className={styles.underText}> */}
      {/*    Выбрать ближайшее доступное время */}
      {/*  </Typography> */}
      {/* </button> */}
    </div>
  );
};

export default SlotSelect;
