import { FieldValues } from 'react-hook-form';

import { Typography } from '../../../../../../1_shared/ui';
import CustomInputPromoCode from '../../../../../../1_shared/ui/CustomInputPromoCode/CustomInputPromoCode';

import styles from './CreateSessionFields.module.scss';
import { IPromoCodeInputFieldProps } from '../../../../model/interfaces/promoCodeInputFieldProps.interface';

const PromoCodeInputField = <T extends FieldValues>(
  props: IPromoCodeInputFieldProps<T>,
) => {
  const {
    name,
    userId,
    slotId,
    promoCode,
    onPromoCodeFind,
    setPromoCode,
    setDisablePromoCode,
    isPromoLoading,
    isDisabled,
    errorPromo,
    setErrorPromo,
    customCreateSession,
  } = props;

  return (
    <div className={styles.fieldRow}>
      <Typography type="bold">Промокод</Typography>
      <CustomInputPromoCode<T>
        name={name}
        className={styles.input}
        userId={userId}
        slotId={slotId}
        promoCode={promoCode}
        onPromoCodeFind={onPromoCodeFind}
        setPromoCode={setPromoCode}
        setDisablePromoCode={setDisablePromoCode}
        isPromoLoading={isPromoLoading}
        isDisabled={isDisabled}
        errorPromo={errorPromo}
        customCreateSession={customCreateSession}
        setErrorPromo={setErrorPromo}
      />
    </div>
  );
};

export default PromoCodeInputField;
