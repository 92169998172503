import { Dispatch, SetStateAction } from 'react';
import useSWRMutation from 'swr/mutation';

import { EReminderType } from '../../../1_shared/config/enums/EReminderType';
import { reminderSlotId } from '../api/details.service';

import { IHookActionOutput } from './interfaces/IHookActionOutput';

const useActionsOrder = (): IHookActionOutput => {
  const { trigger: reminderSwitchTr } = useSWRMutation(
    '/ss/reminder/switch/by-slot',
    reminderSlotId,
  );

  const reminderSwitchAction = async (
    slotId: string,
    type: EReminderType,
    changeState: Dispatch<SetStateAction<boolean>>,
  ) => {
    await reminderSwitchTr({ slotId, type }).then(() =>
      changeState(prevState => !prevState),
    );
  };

  return { reminderSwitchAction };
};

export default useActionsOrder;
