import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Radio } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { sortBy, take } from 'lodash';

import { ESlotType } from '1_shared/config/enums/ESlotType';
import { ISlot } from '1_shared/config/interfaces/ISlot';
import { MAX_SLOTS } from '1_shared/constants/variables';
import { getClosestSlots } from '1_shared/lib/helpers/getClosestSlots';
import { Button, RadioButton, Typography } from '1_shared/ui';
import { PopupSlots } from '3_features';

import useChangeLocalSubscribeStatus from '../../../1_shared/api/hooks/useChangeLocalSubscribeStatus';
import { useSubscriptions } from '../../../1_shared/api/hooks/useNotificationSubcribe';
import { YandexMetricsService } from '../../../1_shared/api/services';
import {
  EYandexInputTypes,
  EYandexPage,
  EYandexTypes,
} from '../../../1_shared/api/services/configs/interfaces/EYandexConfigs';
import { EMessageType } from '../../../1_shared/constants/EMessageType';
import { SpecSubContext } from '../../../1_shared/helpers/SpecSubContext';
import { checkDateNow } from '../../../1_shared/lib/helpers/checkDateNow';
import { isPhoneField } from '../../../4_widgets/LoginForm/lib/checkPhone';

import { ISlotsProps } from './interfaces/ISlotsProps';

import styles from './Slots.module.scss';
import { getUtm } from '../../../1_shared/helpers/getUtm';

const Slots = ({
  slots,
  value,
  onChange,
  spec,
  className = '',
  dark = false,
  label,
  pageType = EYandexPage.Spec,
}: ISlotsProps) => {
  const [open, setOpen] = useState(false);

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  const { subscribe: trigger } = useSubscriptions();
  const { setUserMessageType, setSpecSubName, setIsSubscribed, setSpecId } =
    useContext(SpecSubContext);
  const { subscribeOnSpec } = useChangeLocalSubscribeStatus();

  const { requestSlot } = YandexMetricsService();

  const setContext = (
    contactType: EMessageType | null,
    isSubscribed: boolean,
    specId: string,
    specName?: string,
  ) => {
    setUserMessageType(contactType);
    setSpecSubName(specName ?? '');
    setIsSubscribed(isSubscribed);
    setSpecId(specId);
  };

  const specName = `${spec?.firstName ?? ''} ${spec?.secondName ?? ''}`;

  const subscribe = async () => {
    /** Yandex.Metrics */
    requestSlot(
      EYandexInputTypes.InText,
      EYandexTypes.Click,
      pageType,
      user?.id,
    );
    /** Проверка авторизации пользователя в системе */
    if (!role) {
      setContext(null, true, spec?.id ?? '', specName);
      return navigate('#userNotAuthorized');
    }
    const contactType = isPhoneField(user?.phone || '')
      ? EMessageType.PHONE
      : EMessageType.EMAIL;

    /** Подписка на новые слоты у пользователя */
    return await trigger({
      userContactType: contactType,
      subType: 'SUB_AVAILABLE_SLOTS',
      targetSpecId: spec?.id ?? '',
      utm: getUtm(),
    }).then(() => {
      /** Yandex.Metrics */
      requestSlot(
        EYandexInputTypes.InText,
        EYandexTypes.Send,
        pageType,
        user?.id,
      );

      setUserMessageType(contactType);
      setSpecSubName(`${spec?.firstName ?? ''} ${spec?.secondName}` ?? '');
      setIsSubscribed(true);
      setSpecId(spec?.id ?? '');
      subscribeOnSpec(spec?.id ?? '');
      return navigate('#specSubscription');
    });
  };

  const slotsMemo = useMemo(
    () =>
      getClosestSlots(
        slots.length <= MAX_SLOTS ? slots : take(slots, MAX_SLOTS),
      ),
    [slots],
  );

  const onClose = () => {
    setOpen(prev => !prev);
  };

  const getUnderSlotInfo = (index: number, slot: ISlot, date: string) => {
    // Вывод текста "Супервизия" под слотами супервизии
    if (index !== 0 && slot.kind === ESlotType.Supervision) {
      return <Typography type="description">CУПЕРВИЗИЯ</Typography>;
    }
    // Вывод текста "Сегодня/Завтра/ДД.ММ" для первого слота дня
    if (index === 0) {
      return (
        <Typography type="description">
          {checkDateNow(dayjs(date).format('D.MM'), 'D.MM')}
        </Typography>
      );
    }
    return null;
  };

  return (
    <div className={cn(styles.slotsBlock, { [className]: className !== '' })}>
      <Typography type="description">БЛИЖАЙШИЕ СЛОТЫ</Typography>
      {!slots?.length ? (
        <span>
          Все слоты заняты.{' '}
          <span className={styles.notificationButton} onClick={subscribe}>
            Оставьте заявку
          </span>
          , чтобы получить уведомление, когда появится свободное время у
          специалиста.
        </span>
      ) : (
        <>
          <Radio.Group
            className={styles.slotsWrapper}
            value={value?.slotId}
            buttonStyle="solid"
            onChange={e => {
              onChange(slots.find(el => el.slotId === e.target.value));
            }}
          >
            <div className={styles.slots}>
              {Object.keys(slotsMemo).map(date => (
                <div key={date} className={styles.dateBlock}>
                  <div className={styles.listSlots}>
                    {slotsMemo[date].map((slot: ISlot, index: number) => (
                      <div className={styles.slotItem} key={slot?.slotId}>
                        <RadioButton
                          value={slot.slotId}
                          className={cn({
                            [styles.superVision]:
                              slot.kind === ESlotType.Supervision,
                            [styles.darkSlot]:
                              slot.kind !== ESlotType.Supervision && dark,
                          })}
                        >
                          {dayjs(slot.start).format('HH:mm')}
                        </RadioButton>
                        {getUnderSlotInfo(index, slot, date)}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <Button
                className={cn({ [styles.darkBtn]: dark })}
                type="secondary"
                onClick={onClose}
              >
                {label}
              </Button>
            </div>
          </Radio.Group>
          <PopupSlots
            spec={spec}
            slots={sortBy(slots, 'start')}
            open={open}
            onClose={onClose}
            value={value}
            onChange={onChange}
          />
        </>
      )}
    </div>
  );
};

export default Slots;
