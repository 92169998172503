import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { RoutePath } from '../../config/routes';
import { specApiInstance } from '../apiInstance';

const { useMessage } = message;

const useInterceptors = () => {
  const [messageApi, contextHolder] = useMessage();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { logout } = useAuthContext();

  const errorMessage = (errorText: string) => {
    messageApi.destroy();
    if (!(errorText.includes('Пользователь') && pathname === RoutePath.ORDER)) {
      messageApi.open({
        type: 'error',
        content: errorText,
        duration: errorText?.length > 25 ? 5 : 10,
      });
    }
  };

  specApiInstance.interceptors.response.use(
    request => request,
    async error => {
      if (error?.status === 401) {
        if (logout) {
          navigate(RoutePath.LOGIN, {
            state: { pathname, state, content: error?.response?.data?.message },
          });
          await logout();
        }
        errorMessage(error?.response?.data?.message);
        return error;
      }

      if (error?.response?.data?.message) {
        errorMessage(error.response?.data?.message);
        throw error;
      }

      throw error;
    },
  );

  return { contextHolder, messageApi, errorMessage };
};

export default useInterceptors;
