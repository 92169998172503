import { ReactElement } from 'react';

import { Content, Footer, Page } from '../../../1_shared/ui';
import { AdminSessionsList, Header } from '../../../4_widgets';

const AdminSessions = (): ReactElement => (
  <Page>
    <Header />
    <Content wrapperClass={'containerFullWidth paddingXNone'}>
      <AdminSessionsList />
    </Content>
    <Footer />
  </Page>
);

export default AdminSessions;
