import { Card } from 'antd';
import clsx from 'clsx';

import { ICustomCard } from '../interfaces/ICustomCard';

import styles from './CustomCard.module.scss';

const CustomCard = ({ children, customClassName, ...props }: ICustomCard) => (
  <Card className={clsx(styles.root, customClassName)} {...props}>
    {children}
  </Card>
);

export default CustomCard;
