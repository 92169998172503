import {
  EYandexInputTypes,
  EYandexPage,
  EYandexTypes,
} from './configs/interfaces/EYandexConfigs';

export const yandexMetricsService = () => {
  const requestSlot = (
    input_type: EYandexInputTypes,
    type: EYandexTypes,
    page: EYandexPage,
    userId?: string,
  ) => {
    /** Yandex.Metrics */
    // @ts-ignore
    ym(97788822, 'reachGoal', 'request_slot', {
      isAuthorize: !!userId,
      userId: userId ?? null,
      input_type,
      type,
      page,
    });
  };

  return { requestSlot };
};
