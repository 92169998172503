import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { phoneCountryPrefixCorrection } from '../../../../1_shared/helpers/phoneCountryPrefixCorrection';
import { PhoneOrderInput } from '../../../../3_features';
import { IApplicationForm } from '../interface/IApplicationForm';

interface IOrderFormLoginField {
  user: any;
  status: any;
  errorTimerMessage: any;
  handleChangePhone: any;
  foundUser: any;
  setStatus: Dispatch<SetStateAction<any>>;
  setOpenCode: Dispatch<SetStateAction<any>>;
  setIsLogin: Dispatch<SetStateAction<any>>;
  setErrorTimerMessage: Dispatch<SetStateAction<any>>;
  disabled?: boolean;
}

/**
 * Поле для авторизации и отображения логина пользователя на странице оформления заявки на сессию
 * */
const OrderFormLoginField = (props: IOrderFormLoginField): ReactElement => {
  const { control } = useFormContext<IApplicationForm>();

  return (
    <Controller
      control={control}
      name="login"
      render={({ field: { value, onChange } }) => (
        <PhoneOrderInput
          {...props}
          onChange={onChange}
          value={phoneCountryPrefixCorrection(value)}
        />
      )}
    />
  );
};

export default OrderFormLoginField;
