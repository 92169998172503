export enum AppRoute {
  Main = 'MAIN',
  ClientMain = 'CLIENT_MAIN',
  Specialist = 'SPECIALIST',
  Specialists = 'SPECIALISTS',
  SpecialistPerson = 'SPECIALIST_PERSON',
  Order = 'ORDER',
  CreateOrder = 'CREATE_ORDER',
  PreOrder = 'PREORDER',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Password = 'PASSWORD',
  SpecCab = 'SPECIALIST_CABINET',
  ClientCab = 'CLIENT_CABINET',
  AdminCab = 'ADMIN_CABINET',
  AdminSessionsList = 'ADMIN_SESSIONS_LIST',
  ClientSessions = 'CLIENT_SESSIONS',
  FirstForm = 'FIRST_FORM',
  OrderPage = 'REACT_ORDER_PAGE',
  OrderCurs = 'REACT_ORDER_CURS',
  OrderOk = 'REACT_ORDER_OK',
  NeedHelp = 'NEED_HELP',
  Anketa = 'REACT_ANKETA',
  // BetterPsychological = 'REACT_BETTER_PSYCHOLOGICAL',
  Conference = 'CONFERENCE',
  EditProfile = 'EDIT_PROFILE',
  NotFound = 'NOT_FOUND',
  CheckSession = 'CHECK_SESSION',
  Oauth = 'OAUTH',
}

export const RoutePath: Record<AppRoute, string> = {
  [AppRoute.Main]: '/',
  [AppRoute.NotFound]: '*',
  [AppRoute.ClientMain]: '/client',
  [AppRoute.Specialist]: '/specialist',
  [AppRoute.Specialists]: '/specialists',
  [AppRoute.SpecialistPerson]: '/specialists/specialist/:specIdentification',
  [AppRoute.Order]: '/order',
  [AppRoute.PreOrder]: '/order/:orderId',
  [AppRoute.CreateOrder]: '/pc/create-order',
  [AppRoute.Login]: '/login',
  [AppRoute.Logout]: '/logout',
  [AppRoute.Password]: '/signup/:jwt/:email',
  [AppRoute.SpecCab]: '/pc/spec',
  [AppRoute.AdminCab]: '/pc/admin',
  [AppRoute.AdminSessionsList]: '/pc/admin/sessions',
  [AppRoute.ClientCab]: '/pc/client',
  [AppRoute.ClientSessions]: '/pc/client/sessions',
  [AppRoute.FirstForm]: '/first-form', // все ссылки которые начинаются с r пустые страницы для верстки
  [AppRoute.OrderPage]: '/r-order', // Запись на сессию
  [AppRoute.OrderCurs]: '/r-order-curs', // Запись на курс
  [AppRoute.OrderOk]: '/order-ok', // Оплата прошла
  [AppRoute.NeedHelp]: '/need-help', // Все будет хорошо
  [AppRoute.Anketa]: '/anketa', //  Анкета
  // [AppRoute.BetterPsychological]: '/r-better-psy', // Станьте лучше как психолог
  [AppRoute.Conference]: '/conference',
  [AppRoute.EditProfile]: 'pc/edit-profile',
  [AppRoute.Oauth]: '/oauth/redirect-uri',
  [AppRoute.CheckSession]: '/check-session/:uuid',
};
