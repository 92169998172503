import { ReactElement, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { Breadcrumbs, Typography } from '1_shared/ui';

import { TPromoCode } from '../../../1_shared/config/types/TPromoCode';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { schema } from '../config/schema';

import { breadCrumbsOrderForm } from './constants/breadCrumbsOrderForm';
import { EOrderFormType } from './constants/EOrderFormType';
import { IApplicationForm } from './interface/IApplicationForm';
import OrderFormByType from './OrderFormByType/ui/OrderFormByType';
import OrderFormHelperBlock from './OrderFormComponents/OrderFormHelperBlock';

import styles from './OrderForm.module.scss';

/**
 * TODO: [DOT-?] требуется апнуть схему для резолвера с помощью Zod и строить на основании схемы типизацию формы в зависимости от контекста
 * */
const OrderForm = ({
  orderFormType = EOrderFormType.SESSION_ORDER,
}: {
  orderFormType: EOrderFormType;
}): ReactElement => {
  const location = useLocation();
  const { user } = useAuthContext();
  const userByAnketa = useRef<{ name: string; phone: string }>(
    JSON.parse(localStorage.getItem('userByAnketa') || '{}'),
  );

  const promoCodes = JSON.parse(localStorage.getItem('promoCodes') || '[]');
  const actualPromoCode: TPromoCode = promoCodes?.find(
    (item: TPromoCode) => item?.name,
  ) ?? { name: '' };

  const methods = useForm<IApplicationForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      login: userByAnketa.current?.phone ?? '',
      slotId: (location.state as any)?.slot?.slotId ?? undefined,
      promoCode:
        orderFormType === EOrderFormType.PAYMENT_FORM
          ? undefined
          : actualPromoCode?.name,
      isPromoCodeSubmit: false,
      clientUserId: undefined,
    },
  });
  methods.watch([
    'slotId',
    'promoCode',
    'clientUserId',
    'login',
    'name',
    'code',
  ]);

  return (
    <div className={styles.root}>
      <div>
        <Breadcrumbs items={breadCrumbsOrderForm} />
        <Typography type="h2">
          {orderFormType === EOrderFormType.PAYMENT_FORM
            ? 'Оплатить сеанс'
            : 'Запись на сессию'}
        </Typography>
      </div>
      <FormProvider {...methods}>
        <OrderFormByType
          userByAnketa={userByAnketa}
          formType={orderFormType}
          user={user}
        />
      </FormProvider>
      <OrderFormHelperBlock />
    </div>
  );
};

export default OrderForm;
