import { ReactElement, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Spin } from 'antd';
import useMessage from 'antd/es/message/useMessage';

import { BookClient, GetAllUsersBySpec, GetSpecInfo } from '../../../../../../1_shared/api/services';
import { ELoginStatus } from '../../../../../../1_shared/config/enums/ELoginStatus';
import { configMedia } from '../../../../../SpecialistCard/ui/config/configMedia';
import usePayment from '../../../../model/usePayment';
import { IOrderFormBySpec } from '../../../interface/IApplicationForm';
import OrderFormAgreements from '../../../OrderFormComponents/OrderFormAgreements';
import OrderFormClientSelect from '../../../OrderFormComponents/OrderFormClientSelect/OrderFormClientSelect';
import OrderFormInformationRow from '../../../OrderFormComponents/OrderFormInformationRow';
import OrderFormPromoCodeField from '../../../OrderFormComponents/OrderFormPromoCodeField/OrderFormPromoCodeField';
import OrderFormSpecialistField from '../../../OrderFormComponents/OrderFormSpecialistField';

import styles from '../../../OrderForm.module.scss';
import { ESpecStatus } from '../../../../../../1_shared/config/enums/ESpecStatus';
import { useNavigate } from 'react-router';

/**
 * Компонент формы для создания новой сессии с определенным клиентом
 * */
const OrderFormCreateSession = ({
  status,
}: {
  status: ELoginStatus;
}): ReactElement => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const navigate = useNavigate();
  const [messageApi, contextHolder] = useMessage();

  const { data: allSpecClients, isLoading: allSpecClientsLoading } =
    GetAllUsersBySpec();
  const { specData, specDataLoading } = GetSpecInfo(user?.id ?? '');
  const { createSession } = BookClient();

  const { handleSubmit, reset, getValues } = useFormContext<IOrderFormBySpec>();
  const [slotId, userId] = useWatch({ name: ['slotId', 'clientUserId'] });

  const {
    onPromoCodeFind,
    promoCode,
    isPromoLoading,
    errorPromo,
    setErrorPromo,
    setPromoCode,
  } = usePayment();

  const price = useMemo(() => {
    const slot = specData?.slots.find(slot => slot.slotId === slotId);
    return (
      specData?.sessionsInfo.find(info => info.sessionType === slot?.kind)
        ?.price ?? 0
    );
  }, [slotId]);

  useEffect(() => {
    if (!getValues('promoCode')) {
      setErrorPromo(null);
    }
  }, [getValues('promoCode'), setErrorPromo]);

  const avatar = useMemo(
    () =>
      specData?.mediaContentResponse.find(el => el.isPrimary)
        ?.mediaContentResponse.previewLink ??
      specData?.mediaContentResponse[0]?.mediaContentResponse.previewLink ??
      configMedia.mediaContentResponse.previewLink,
    [specData],
  );

  const onSubmit = async (data: IOrderFormBySpec) => {
    await createSession({
      clientUserId: data?.clientUserId ?? '',
      slotId: data?.slotId ?? '',
      price: promoCode?.basic_price ?? price ?? 0,
      discount: promoCode?.discount ?? 0,
      discountedPrice: promoCode?.discounted_price ?? price ?? 0,
      promoCode: promoCode?.promo_code ?? undefined,
    });
    reset({});
    messageApi.success({
      content: `Заявка на сессию создана успешно! Клиенту отправлена ссылка для оплаты`,
      duration: 5,
    });
  };

  useEffect(() => {
    if (user.status !== ESpecStatus.Active) navigate('/pc/spec');
  }, []);

  return user?.userId ? (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
      <div className={styles.formGrid}>
        <OrderFormSpecialistField
          isLoading={specDataLoading}
          spec={specData}
          setPromoCode={setPromoCode}
          avatar={avatar}
          customCreateSession
        />
        <OrderFormClientSelect<IOrderFormBySpec>
          name="clientUserId"
          options={(allSpecClients || []).map(client => ({
            label: client.nickname,
            value: client.clientUserId,
          }))}
          optionsLoading={allSpecClientsLoading}
        />
        <OrderFormPromoCodeField
          {...{
            promoCode,
            userId,
            onPromoCodeFind,
            isPromoLoading,
            errorPromo,
            setErrorPromo,
            setPromoCode,
            slotId,
          }}
          customCreateSession
          disableChange={false}
        />
        <OrderFormAgreements />
      </div>
      <OrderFormInformationRow<IOrderFormBySpec>
        {...{
          user,
          promoCode,
          slotId,
          status,
          price,
        }}
        customCreateSession
      />
      {contextHolder}
    </form>
  ) : (
    <Spin size="large" />
  );
};

export default OrderFormCreateSession;
