import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Typography } from '../../../1_shared/ui';
import { PanelBtnsConfig } from '../model/config/PanelBtnsConfig';

import CreateSessionModal from './CreateSession/ui/CreateSessionModal';

import styles from './PcAdminPanel.module.scss';
import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { UserRoutes } from '../../../1_shared/constants/userRoutes';

const PcAdminPanel = (): ReactElement => {
  const role = localStorage.getItem('role') as ERoles;
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);

  const btnsConfig = PanelBtnsConfig({ navigate, setModalOpen });

  useEffect(() => {
    if (role !== ERoles.Admin) {
      navigate(UserRoutes?.[role]);
    }
  }, []);

  return (
    <>
      <div className={styles.container}>
        {btnsConfig.map(element => (
          <Card key={element.label} hoverable onClick={element.action}>
            <Typography className={styles.cardLabel}>
              {element.label}
            </Typography>
          </Card>
        ))}
      </div>
      <CreateSessionModal
        open={modalOpen}
        closable
        footer={null}
        onCancel={event => {
          event.preventDefault();
          setModalOpen(prevState => !prevState);
        }}
      />
    </>
  );
};

export default PcAdminPanel;
