import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Radio } from 'antd';
import Tooltip from 'antd/lib/tooltip';
import dayjs from 'dayjs';

import { Button, Input, Radio as CustomRadio, Typography } from '1_shared/ui';

import { ReactComponent as TelegramIcon } from '../../../1_shared/assets/pictures/telegram.svg';
import { ELoginStatus } from '../../../1_shared/config/enums/ELoginStatus';
import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { apiLinksByEnv } from '../../../1_shared/constants/apiLinksByEnv';
import { TelegramHelpLink } from '../../../1_shared/constants/TelegramHelpLink';
import { SpecSubContext } from '../../../1_shared/helpers/SpecSubContext';
import { phoneFormConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { CustomTypography } from '../../../1_shared/ui/CustomTypography';
import YandexReactCaptcha from '../../../1_shared/ui/YandexReactCaptcha/YandexReactCaptcha';
import { env } from '../../../env';
import { authorizeSchema, IAuthorizeSchema } from '../config/schemas';
import { isPhoneField } from '../lib/checkPhone';
import useSubmit from '../model/useSubmit';

import styles from './LoginForm.module.scss';

const LoginForm = ({
  isClient = false,
  setReset,
  isNeedRedirect = true,
  onClose,
  showLoginForm = true,
}: {
  isClient?: boolean;
  setReset?: any;
  isNeedRedirect?: boolean;
  onClose?: any;
  showLoginForm?: boolean;
}) => {
  const { state, pathname }: any = useLocation();
  const [token, setToken] = useState<string | null>(null);
  const specSubContext = useContext(SpecSubContext); //

  /** Yandex.Captcha показ каптчи при слишком частых попытках входа */
  const now = dayjs().unix();
  const getTimeStamp = localStorage.getItem('loginAuthorize');
  const timestamp =
    (getTimeStamp && Number(getTimeStamp)) ?? dayjs(now).unix() - 2 * 120;
  const isVisible = !(now - Number(timestamp) > 120);

  const {
    onSubmit,
    resetStates,
    isLogin,
    setIsLogin,
    regErrorMessage,
    loginError,
    isPasswordPage,
    loginStatus,
    isRoleSelected,
    sendCode,
  } = useSubmit(
    onClose,
    state?.isNeedRedirect ?? isNeedRedirect,
    state?.route,
    showLoginForm,
  );

  const submitHandler = (data: any) => {
    if (loginStatus === ELoginStatus.CodeSend)
      localStorage.setItem('loginAuthorize', dayjs().unix().toString());
    onSubmit(data);
  };

  const [disableSendAgain, setDisableSendAgain] = useState(false);

  const { email } = useParams();
  const { control, handleSubmit, formState, getValues, watch, reset } =
    useForm<IAuthorizeSchema>({
      reValidateMode: 'onChange',
      mode: 'all', // onChange
      resolver: zodResolver(authorizeSchema),
      defaultValues: {
        login: email ?? '',
        role: ERoles.Client,
        password: null,
        surname: null,
        firstName: null,
        passwordConfirmation: null,
        code: null,
        nickname: null,
        isReg: false,
      },
    });
  watch(['role', 'login', 'password', 'passwordConfirmation', 'isReg']);
  // const isReg = !isLogin || isPasswordPage;
  const [loginValue, roleValue, password, passwordConfirm, isReg] = getValues([
    'login',
    'role',
    'password',
    'passwordConfirmation',
    'isReg',
  ]);

  const resetValues = () => {
    reset({
      login: loginValue,
      password: null,
      code: null,
      role: roleValue,
      isReg,
    });
  };

  const SSOSubscribeHandler = () => {
    localStorage.setItem(
      'subscribeSlotData',
      JSON.stringify({
        // userMessageType: specSubContext?.userMessageType || null,
        specId: specSubContext?.specId || null,
        needSlotSubscribeAfterLogin: true,
      }),
    );
  };

  useEffect(() => {
    if (setReset) {
      setReset(reset);
    }
  }, []);

  useEffect(() => {
    if (
      (!formState.isValid || loginStatus === ELoginStatus.None) &&
      !isPhoneField(loginValue)
    ) {
      resetValues();
    } else if (
      (!formState.isValid || loginStatus === ELoginStatus.None) &&
      isPhoneField(loginValue)
    ) {
      resetValues();
    }
  }, [loginStatus]);

  useEffect(() => {
    if (loginStatus === ELoginStatus.CodeSend) {
      const defaultFormFields = {
        login: loginValue,
        role: roleValue,
        code: '',
        isReg,
      };

      if (roleValue === ERoles.Client) {
        if (isPhoneField(loginValue)) {
          reset({
            ...defaultFormFields,
            nickname: '',
          });
        } else {
          reset({
            ...defaultFormFields,
            nickname: '',
            password: '',
            passwordConfirmation: '',
          });
        }
      } else if (roleValue === ERoles.Spec) {
        if (isPhoneField(loginValue)) {
          reset({
            ...defaultFormFields,
            surname: '',
            firstName: '',
          });
        } else {
          reset({
            ...defaultFormFields,
            surname: '',
            firstName: '',
            password: '',
            passwordConfirmation: '',
          });
        }
      }
    }
  }, [loginStatus]);

  useEffect(() => {
    if (loginStatus === ELoginStatus.CodeSend) {
      const timer = setTimeout(() => {
        setDisableSendAgain(true);
      }, 120000);

      return () => clearTimeout(timer);
    }
    if (sendCode) {
      setDisableSendAgain(false);
    }
    return undefined;
  }, [loginStatus]);

  return (
    <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
      <Typography type="title" className={styles.formTitle}>
        {`${isReg ? 'Регистрация' : 'Вход'} на платформу`}
      </Typography>
      <div className={styles.itemWrapper}>
        <Controller
          control={control}
          name="login"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <div className={styles.input}>
              <Input
                maxLength={isPhoneField(value) && !!value ? 16 : undefined}
                name="login"
                value={value}
                placeholder="+7 999 999 99 99"
                onChange={e => {
                  const val = e.target.value;

                  if (isPhoneField(val)) {
                    resetStates(onChange, phoneFormConvert(val));
                  } else {
                    resetStates(onChange, val);
                  }
                }}
                label="ТЕЛЕФОН ИЛИ E-MAIL"
              />
              {error && (
                <CustomTypography type="description" className={styles.error}>
                  {error.message}
                </CustomTypography>
              )}
            </div>
          )}
        />
        {!isLogin && regErrorMessage && (
          <Typography type="description" className={styles.error}>
            {regErrorMessage}
          </Typography>
        )}
        {isLogin && loginError && (
          <Typography type="description" className={styles.error}>
            {loginError}
          </Typography>
        )}
        {isReg && !isClient && (
          <Controller
            control={control}
            name="role"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div>
                <Radio.Group
                  value={value}
                  onChange={onChange}
                  className={styles.radioGroup}
                  disabled={isRoleSelected}
                >
                  <CustomRadio value={ERoles.Client} checked>
                    <Typography className={styles.radioBtnText}>
                      Я клиент
                    </Typography>
                  </CustomRadio>
                  <CustomRadio value={ERoles.Spec}>
                    <Typography className={styles.radioBtnText}>
                      Я специалист
                    </Typography>
                  </CustomRadio>
                </Radio.Group>
                {error && (
                  <CustomTypography type="description" className={styles.error}>
                    {error.message}
                  </CustomTypography>
                )}
              </div>
            )}
          />
        )}
      </div>
      {loginStatus === ELoginStatus.CodeSend && !isLogin && (
        <div className={styles.itemWrapper}>
          {getValues('role') === ERoles.Client ? (
            <Controller
              control={control}
              name="nickname"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className={styles.input}>
                  <Input
                    value={value || ''}
                    onChange={onChange}
                    label="ПСЕВДОНИМ"
                  />
                  {error && (
                    <CustomTypography
                      type="description"
                      className={styles.error}
                    >
                      {error.message}
                    </CustomTypography>
                  )}
                </div>
              )}
            />
          ) : (
            <div className={styles.fioWrapper}>
              <Controller
                control={control}
                name="surname"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <div className={styles.input}>
                    <Input
                      value={value || ''}
                      onChange={onChange}
                      label="Фамилия"
                    />
                    {error && (
                      <CustomTypography
                        type="description"
                        className={styles.error}
                      >
                        {error.message}
                      </CustomTypography>
                    )}
                  </div>
                )}
              />
              <Controller
                control={control}
                name="firstName"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <div className={styles.input}>
                    <Input
                      value={value || ''}
                      onChange={onChange}
                      label="ИМЯ"
                    />
                    {error && (
                      <CustomTypography
                        type="description"
                        className={styles.error}
                      >
                        {error.message}
                      </CustomTypography>
                    )}
                  </div>
                )}
              />
              <Controller
                control={control}
                name="patronymic"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <div className={styles.input}>
                    <Input
                      value={value || ''}
                      onChange={onChange}
                      label="ОТЧЕСТВО"
                    />
                    {error && (
                      <CustomTypography
                        type="description"
                        className={styles.error}
                      >
                        {error.message}
                      </CustomTypography>
                    )}
                  </div>
                )}
              />
            </div>
          )}
        </div>
      )}
      {(loginStatus === ELoginStatus.CodeSend ||
        loginStatus === ELoginStatus.CodeSended) && (
        <div className={styles.itemWrapper}>
          <Controller
            control={control}
            name="code"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={styles.input}>
                <Input value={value || ''} onChange={onChange} label="КОД" />
                {error && (
                  <CustomTypography type="description" className={styles.error}>
                    {error.message}
                  </CustomTypography>
                )}
                <Tooltip
                  placement="leftTop"
                  title={`${!disableSendAgain ? 'Повторное получение кода будет доступно через 2 минуты' : ''}`}
                >
                  <div className={styles.sendAgainWrapp}>
                    <Button
                      type="link"
                      htmlType="button"
                      onClick={() => sendCode({ login: getValues('login') })}
                      className={styles.sendAgainBtn}
                      disabled={!disableSendAgain}
                    >
                      <Typography
                        type="description"
                        className={styles.sendAgainBtnText}
                      >
                        Запросить еще раз
                      </Typography>
                    </Button>
                  </div>
                </Tooltip>
              </div>
            )}
          />
        </div>
      )}
      {((loginStatus === ELoginStatus.NeedPassword && isLogin) ||
        isPasswordPage ||
        (loginStatus === ELoginStatus.CodeSend &&
          !isPhoneField(getValues('login')))) && (
        <div className={styles.itemWrapper}>
          <Controller
            control={control}
            name="password"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={styles.input}>
                <Input
                  type="password"
                  value={value || ''}
                  onChange={onChange}
                  label="ВВЕДИТЕ ПАРОЛЬ"
                />
                {error && (
                  <CustomTypography type="description" className={styles.error}>
                    {error.message}
                  </CustomTypography>
                )}
              </div>
            )}
          />
          {loginStatus === ELoginStatus.CodeSend && (
            <Controller
              control={control}
              name="passwordConfirmation"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className={styles.input}>
                  <Input
                    type="password"
                    value={value || ''}
                    onChange={onChange}
                    label="ПОДТВЕРЖДЕНИЕ ПАРОЛЯ"
                  />
                  {error &&
                    !error.message?.includes(
                      'Пароли должны быть одинаковыми',
                    ) && (
                      <CustomTypography
                        type="description"
                        className={styles.error}
                      >
                        {error.message}
                      </CustomTypography>
                    )}
                  {error &&
                    error.message?.includes('Пароли должны быть одинаковыми') &&
                    password !== passwordConfirm && (
                      <CustomTypography
                        type="description"
                        className={styles.error}
                      >
                        {error.message}
                      </CustomTypography>
                    )}
                </div>
              )}
            />
          )}
        </div>
      )}

      {/** Yandex Captcha */}
      {isVisible && (
        <section className={styles.captchaContainer}>
          <YandexReactCaptcha setToken={setToken} />
        </section>
      )}

      <div className={styles.btnWrapper}>
        <Button
          disabled={isVisible && !token}
          htmlType="submit"
          className={styles.btnLogin}
        >
          <Typography className={styles.textInBtn}>
            {isLogin || isPasswordPage ? 'ВОЙТИ' : 'ЗАРЕГИСТРИРОВАТЬСЯ'}
          </Typography>
        </Button>
        {(env.ALLOW_SSO === 'true' || env.NODE_ENV === 'development') &&
          !isReg && (
            <Button
              className={styles.btnSSO}
              type="secondary"
              href={`${apiLinksByEnv}/aim/oauth2/authorization/instudy`}
              onClick={() =>
                pathname === '/login' ? null : SSOSubscribeHandler()
              }
              target="_self"
              rel="noopener noreferrer"
            >
              <Typography className={styles.textInBtn}>
                ВОЙТИ ЧЕРЕЗ INSTUDYID
              </Typography>
            </Button>
          )}
        <section className={styles.formFooter}>
          {!isPasswordPage && (
            <div className={styles.textWrapper}>
              <Typography className={styles.accountCheckText}>
                {isLogin ? 'Еще нет аккаунта?' : 'Уже есть аккаунт?'}
              </Typography>
              <button
                type="button"
                className={styles.btn}
                onClick={() => {
                  setIsLogin(!isLogin);
                  reset({ isReg: !isReg, role: ERoles.Client });
                }}
              >
                <Typography className={styles.btnText}>
                  {isLogin ? 'Зарегистрируйтесь' : 'Войдите'}
                </Typography>
              </button>
            </div>
          )}
          <div className={styles.textWrapper}>
            <TelegramIcon />
            <Typography className={styles.textHelp}>Нужна помощь?</Typography>
            <a className={styles.textHelpBtn} href={TelegramHelpLink}>
              Напишите нам
            </a>
          </div>
        </section>
      </div>
    </form>
  );
};

export default LoginForm;
