import { z } from 'zod';

export const createSessionByAdminSchema = z.object({
  specUserId: z.string(),
  slotId: z.string().nullable(),
  clientUserId: z.string().nullable(),
  promoCode: z.string().optional(),
  isPromoCodeSubmit: z.boolean().optional().default(false),
  userInfoForUpdate: z.string().optional().default(''),
});

export type TSessionByAdminSchema = z.infer<typeof createSessionByAdminSchema>;
