import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import { Button, Typography } from '../../../1_shared/ui';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';

import styles from './NotFound.module.scss';

const NotFound = () => {
  const navigate = useNavigate();
  const { role } = useAuthContext();

  return (
    <div className={styles.root}>
      {role ? (
        <div className={styles.titleWrap}>
          <Spin percent="auto" size="large" />
          <Typography type="title">Загружаем профиль</Typography>
        </div>
      ) : (
        <div className={styles.titleWrap}>
          <Typography type="title">404</Typography>
          <Button type="primary" onClick={() => navigate('/')}>
            ВЕРНУТЬСЯ НА ГЛАВНУЮ
          </Button>
        </div>
      )}
    </div>
  );
};

export default NotFound;
