import { ReactElement } from 'react';
import { Spin } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';

import { configMonth } from '../../../../../1_shared/config/configMonth';
import { ISpecialistShortData } from '../../../../../1_shared/config/interfaces/ISpecialistShortData';
import { timeConfig } from '../../../../../1_shared/config/timeConfig';
import { Select, Typography } from '../../../../../1_shared/ui';
import { SpecialistAvatar } from '../../../../../2_entities';
import { configMedia } from '../../../../SpecialistCard/ui/config/configMedia';

import styles from '../../OrderForm.module.scss';
import { IOrderForPaymentResponse } from '../../../../../1_shared/api/services/configs/interfaces/clientService.interface';

const OrderFormSpecialistSimpleField = ({
  orderData,
  orderDataLoading,
}: {
  orderData?: IOrderForPaymentResponse;
  orderDataLoading: boolean;
}): ReactElement => {
  const timePoint = dayjs(orderData?.timePoint);
  const slot = {
    value: orderData?.slotId,
    label: `${timePoint.format('DD')} ${configMonth[timePoint.format('MM')]} ${timePoint.format('HH:mm')}`,
  };

  return (
    <>
      <div className={cn(styles.row)}>
        <Typography type="subtitle" className={styles.rowText}>
          СПЕЦИАЛИСТ
        </Typography>
        {orderDataLoading ? (
          <Spin />
        ) : (
          <SpecialistAvatar
            firstName={String(
              (orderData?.specialist as unknown as ISpecialistShortData)
                ?.firstName,
            )}
            lastName={String(
              (orderData?.specialist as unknown as ISpecialistShortData)
                ?.secondName,
            )}
            imgPreview={
              orderData?.specialist?.avatar ||
              configMedia.mediaContentResponse.previewLink
            }
          />
        )}
      </div>
      <div className={cn(styles.row, styles.specialistField)}>
        <Typography type="subtitle" className={styles.rowText}>
          ДАТА И ВРЕМЯ
        </Typography>
        <div className={styles.dateSelect}>
          <Select options={[slot]} value={slot} disabled />
        </div>
        {orderData?.slotDuration && (
          <Typography
            type="description"
            className={cn(styles.descriptionFontSize)}
          >
            {`Длительность сеанса ${timeConfig[orderData?.slotDuration]}`}
          </Typography>
        )}
      </div>
    </>
  );
};

export default OrderFormSpecialistSimpleField;
