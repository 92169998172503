import { ReactElement, useEffect, useMemo, useState } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { Button, Typography } from '../../../../1_shared/ui';

import styles from '../OrderForm.module.scss';
import { formatedPrice } from '../../../../1_shared/helpers/formatedPrice';
import { IOrderFormInformationRow } from '../interface/orderFormInformationRow.interface';

/**
 * Отображение итоговой информации о заявке с кнопкой оплаты
 * */
const OrderFormInformationRow = <T extends FieldValues>(
  props: IOrderFormInformationRow,
): ReactElement => {
  const { getValues } = useFormContext<T>();

  const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);

  const {
    user,
    promoCode,
    slotId,
    status,
    price,
    disabled,
    isPaymentForm = false,
    customCreateSession = false,
  } = props;

  const isDisabled = useMemo(
    () => disabled || !user?.userId || !getValues('name' as Path<T>),
    [getValues('name' as Path<T>), status, user?.userId, disabled],
  );

  const isCustomDisabled = useMemo(
    () =>
      !getValues('slotId' as Path<T>) || !getValues('clientUserId' as Path<T>),
    [getValues('slotId' as Path<T>), getValues('clientUserId' as Path<T>)],
  );

  useEffect(() => {
    if (isPaymentForm) {
      setIsSubmitAvailable(false);
    }

    if (!isPaymentForm) {
      setIsSubmitAvailable(customCreateSession ? isCustomDisabled : isDisabled);
    }
  }, [isPaymentForm, customCreateSession, isCustomDisabled, isDisabled]);

  return (
    <div className={styles.btnWrapper}>
      <Typography type="subtitle" className={styles.rowText}>
        ИТОГО
      </Typography>
      <div className={styles.costWrapper}>
        <Typography type="title" className={styles.rowText}>
          {`${formatedPrice(promoCode && slotId ? promoCode.discounted_price : price)}`}
        </Typography>
        <Button
          disabled={isSubmitAvailable}
          htmlType="submit"
          className={styles.btn}
        >
          {customCreateSession ? 'СОЗДАТЬ' : 'ОПЛАТИТЬ'}
        </Button>
      </div>
    </div>
  );
};

export default OrderFormInformationRow;
