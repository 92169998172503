import { mutation, query } from '../../apiInstance';

import {
  IClientsByInfo,
  ICreateSessionBuAdminResponse,
  ICreateSessionByAdminRequest,
  ISessionsByAdminResponse,
  ISlotsInfoBySpecId,
  ISpecByName,
  IUpdateUserInfoByAdminRequest,
} from './interfaces/adminService.interface';
import { ICancelInput } from '../../../../3_features/SessionsTable/api/interfaces/ICancelInput';
import { ERequestType } from '../../interfaces/ERequestType';

export const specsByName = query<null, ISpecByName[]>();
export const slotsBySpecId = query<null, ISlotsInfoBySpecId[]>();
export const clientByInfo = query<null, IClientsByInfo[]>();
export const sessionsByAdmin = query<null, ISessionsByAdminResponse[]>();

export const createSessionByAdmin = mutation<
  ICreateSessionByAdminRequest,
  ICreateSessionBuAdminResponse
>();
export const cancelSessionByAdmin = mutation<ICancelInput, null>();
export const updateUserInfoByAdmin = mutation<
  IUpdateUserInfoByAdminRequest,
  null
>(ERequestType.Patch);
