import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';

import { specApiInstance } from '../../../1_shared/api/apiInstance';
import { EReminderType } from '../../../1_shared/config/enums/EReminderType';
import { TelegramHelpLink } from '../../../1_shared/constants/TelegramHelpLink';
import { phoneConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { CheckboxCircle } from '../../../1_shared/ui';
import useActionsOrder from '../model/useActionsOrder';
import useGetCalendarEvent from '../model/useGetCalendarEvent';

import { ILocationDetails } from './interfaces/ILocationDetails';

import 'app/styles/global/global.scss';
import './order-details.scss';

const OrderDetails = () => {
  const location = useLocation();
  const state = location.state as ILocationDetails;
  const { reminderSwitchAction } = useActionsOrder();
  const getSessionCalendarEvent = useGetCalendarEvent();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const [remindByPhone, setRBP] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      await specApiInstance
        .get(`ls/api/v2/promocodes/byUserId?userId=${user?.userId}`)
        .then(response => {
          if (response?.data)
            localStorage.setItem('promoCodes', JSON.stringify(response?.data));
        });
    }, 1500);

    const timeout2 = setTimeout(async () => {
      if (state?.phone) {
        await reminderSwitchAction(
          state?.slot?.slotId || '',
          EReminderType.Phone,
          setRBP,
        );
        return;
      }

      await reminderSwitchAction(
        state?.slot?.slotId || '',
        EReminderType.Email,
        setRBP,
      );
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
    };
  }, []);

  return (
    <section className="order-details">
      <div className="container">
        <div className="order-details__inner">
          <h2 className="order-details__title section-title">
            Параметры вашего заказа
          </h2>

          <ul className="order-details__list">
            {state?.name && (
              <li className="order-details__item">
                <span className="order-details__label">
                  Ваши имя (псевдоним)
                </span>
                <p>{state.name}</p>
              </li>
            )}
            {state?.email && (
              <li className="order-details__item">
                <span className="order-details__label">Email</span>
                <p>{state.email}</p>
                {state?.slot?.slotId && (
                  <CheckboxCircle
                    onChange={() =>
                      reminderSwitchAction(
                        state?.slot?.slotId || '',
                        EReminderType.Email,
                        setRBP,
                      )
                    }
                  >
                    Оповещать
                  </CheckboxCircle>
                )}
              </li>
            )}
            {state?.phone && (
              <li className="order-details__item">
                <span className="order-details__label">Телефон</span>
                <p>{phoneConvert(state?.phone)}</p>
                {state?.slot?.slotId && (
                  <CheckboxCircle
                    checked={remindByPhone}
                    onChange={() =>
                      reminderSwitchAction(
                        state?.slot?.slotId || '',
                        EReminderType.Phone,
                        setRBP,
                      )
                    }
                  >
                    Оповещать
                  </CheckboxCircle>
                )}
              </li>
            )}
            {state?.spec?.secondName && state?.spec?.firstName && (
              <li className="order-details__item">
                <span className="order-details__label">Специалист</span>
                <p>{`${state?.spec?.secondName} ${state?.spec?.firstName}`}</p>
              </li>
            )}
            {state?.slot && (
              <li className="order-details__item">
                <span className="order-details__label">Дата и время</span>
                <p>{dayjs(state.slot.start).format('DD MMMM, HH:mm')}</p>
                <button
                  type="button"
                  onClick={() => {
                    if (state?.slot)
                      getSessionCalendarEvent(state?.slot?.slotId);
                  }}
                  className="order-details__control order-details__control--calendar"
                >
                  Добавить в календарь
                </button>
              </li>
            )}
            {state?.cost !== undefined && (
              <li className="order-details__item">
                <span className="order-details__label">Стоимость</span>
                <p>{`${state?.cost} ₽`}</p>
              </li>
            )}
          </ul>

          <div className="order-details__connect">
            <p>Заметили ошибку? Поможем исправить</p>
            <a
              href={TelegramHelpLink}
              className="order-details__connect-btn btn btn--light btn--mini"
            >
              Напишите нам в Телеграмм
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderDetails;
