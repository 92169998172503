import { ReactElement } from 'react';
import cn from 'classnames';

import pdf_offerAgreement from '../../../../1_shared/assets/docs/Договор_клиентский_оферты_ООО_ПКО.pdf';
import pdf_dataProcessing
  from '../../../../1_shared/assets/docs/Договор_Обработки_персональных_данных_специалист_DoTherapy.pdf';
import pdf_userAgreement from '../../../../1_shared/assets/docs/Пользовательское_соглашение_DoTherapy.pdf';
import MajesticLock from '../../../../1_shared/assets/pictures/majesticonsLock.svg';
import { Typography } from '../../../../1_shared/ui';

import styles from '../OrderForm.module.scss';

/**
 * Блок с соглашениями об обработке информации на странице заявки на сессию
 * */
const OrderFormAgreements = (): ReactElement => (
  <div className={styles.agreementBlock}>
    <img src={MajesticLock} alt="filter" className={styles.lockIcon} />
    <div>
      <Typography className={styles.textAgreement}>
        Нажимая кнопку “Оплатить” Вы даете{' '}
        <Typography className={cn(styles.helperText)}>
          <a
            href={pdf_dataProcessing}
            target="_blank"
            data-title="A new page"
            rel="noopener noreferrer"
            className={cn(styles.offerAgreement, styles.helperText)}
          >
            согласие
          </a>
        </Typography>{' '}
        на обработку Ваших персональных данных, принимаете{' '}
        <Typography className={cn(styles.helperText)}>
          <a
            href={pdf_userAgreement}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(styles.offerAgreement, styles.helperText)}
          >
            пользовательское соглашение
          </a>
        </Typography>
        {' и '}
        <Typography className={cn(styles.helperText)}>
          <a
            href={pdf_offerAgreement}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(styles.offerAgreement, styles.helperText)}
          >
            договор оферты
          </a>
        </Typography>
      </Typography>
    </div>
  </div>
);

export default OrderFormAgreements;
