import { ReactElement } from 'react';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { Select } from 'antd';

import { Typography } from '../../../../../../1_shared/ui';

import styles from './CreateSessionFields.module.scss';

const FieldSelector = <T extends FieldValues>({
  name,
  label,
  options,
  disabled = false,
  placeholder,
  showSearch = false,
  loading = false,
  onSearch,
  onChange,
}: {
  name: Path<T>;
  label: string;
  options: { value: string; label: string }[];
  placeholder: string;
  disabled?: boolean;
  showSearch?: boolean;
  loading?: boolean;
  onSearch?: (value: string) => void;
  onChange?: (option: any) => void;
}): ReactElement => {
  const { setValue, control } = useFormContext<T>();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className={styles.fieldRow}>
          <Typography className={styles.label} type="bold">
            {label}
          </Typography>
          <Select
            {...field}
            className={styles.select}
            onChange={(value, option) => {
              setValue(name, value);
              if (onChange) onChange(option);
            }}
            maxTagCount="responsive"
            disabled={disabled}
            placeholder={placeholder}
            showSearch={showSearch}
            loading={loading}
            onSearch={onSearch}
            options={options.map((item: any) => ({
              ...item,
              searchText:
                `${item?.label} ${item?.phone} ${item?.email}`.toLowerCase(),
              label: (
                <div>
                  <Typography type={'p'}>{item.label}</Typography>
                  <section className={styles.mainPromoInfo}>
                    {item?.email && (
                      <Typography type="description">
                        {`E-mail: ${item?.email}`}
                      </Typography>
                    )}
                    {item?.phone && (
                      <Typography type="description">
                        {`Телефон: ${item?.phone}`}
                      </Typography>
                    )}
                  </section>
                </div>
              ),
            }))}
            filterOption={(input, option: any) =>
              (option?.searchText ?? '').includes(input.toLowerCase())
            }
          />
        </div>
      )}
    />
  );
};

export default FieldSelector;
