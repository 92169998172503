import { ReactElement } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { FilterFunc } from 'rc-select/lib/Select';

import { Select, Typography } from '1_shared/ui';

import { IClientsSelect, IOption } from './interfaces/IClientsSelect';

import styles from './СlientsSelect.module.scss';

const OrderFormClientSelect = <T extends FieldValues>({
  name,
  rules,
  className,
  options,
  optionsLoading = false,
}: IClientsSelect<T>): ReactElement => {
  const { control } = useFormContext<T>();

  const filteredOptionFnc: FilterFunc<IOption> = (inputValue, option) =>
    option
      ? (option.label as string)
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      : false;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <div className={styles.row}>
          <Typography type="subtitle" className={styles.fieldLabel}>
            КЛИЕНТ
          </Typography>
          <Select
            {...field}
            className={className}
            placeholder="Выберите клиента"
            showSearch
            filterOption={filteredOptionFnc}
            options={options}
            loading={optionsLoading}
          />
        </div>
      )}
    />
  );
};

export default OrderFormClientSelect;
