import { useMemo } from 'react';
import dayjs from 'dayjs';

import { ISlotsInfoBySpecId } from '../../../1_shared/api/services/configs/interfaces/adminService.interface';
import { configMonth } from '../../../1_shared/config/configMonth';
import { ESlotType } from '../../../1_shared/config/enums/ESlotType';
import { ISlot } from '../../../1_shared/config/interfaces/ISlot';
import { kindConfig } from '../../../1_shared/config/kindConfig';

import { ICreateOptionsOutput } from './interfaces/ICreateOptionsOutput';

const useCreateOptions = (
  slots: (ISlot | ISlotsInfoBySpecId)[],
): ICreateOptionsOutput => {
  const options = useMemo(
    () =>
      slots.map(slot => ({
        value: slot.slotId,
        label: `${dayjs(slot.start).format('DD')} ${configMonth[dayjs(slot.start).format('MM')]} ${dayjs(slot.start).format('HH:mm')} ${slot?.kind === ESlotType.Supervision ? kindConfig[slot?.kind] : ''}`,
      })),
    [slots],
  );

  return { options };
};

export default useCreateOptions;
