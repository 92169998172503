import { ReactElement, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { RoutePath } from '1_shared/config/routes';
import { InfoSpecialist, SpecSpecialization } from '2_entities';
import { PreviewImage, Slots, SpecialistNavigate } from '3_features';

import { IOption } from '../../../1_shared/config/interfaces/IOption';
import { ISlot } from '../../../1_shared/config/interfaces/ISlot';
import { Typography } from '../../../1_shared/ui';
import SpecDescription from '../../../2_entities/SpecDescription/ui/SpecDescription';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { ISpecialistCardProps } from './interfaces/ISpecialistCardProps';

import styles from './SpecialistCard.module.scss';

const SpecialistCard = ({
  spec,
  dictionary,
  dark = false,
  slicedBlock = false,
  className,
  showDescription = false,
  showBriefAboutYourself = false,
  showWorkMethods = false,
  showDuration = true,
  pageType,
}: ISpecialistCardProps): ReactElement => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const subscriptions = JSON.parse(
    localStorage.getItem('subscriptions') || '[]',
  );

  const onSubmit = (data?: ISlot) => {
    // @ts-ignore
    ym(97788822, 'reachGoal', 'booking', {
      isAuthorize: !!user,
      userId: user?.id ?? null,
    });
    navigate(RoutePath.ORDER, {
      state: { id: spec.id, slot: data || spec.slots[0] },
    });
  };

  const workMethods = useMemo(
    () =>
      dictionary
        ? spec?.workMethods
            ?.map(
              spec =>
                dictionary?.find((option: IOption) => option.id === spec)
                  ?.label ?? '',
            )
            .join(', ')
        : null,
    [],
  );

  const isSubscribed = subscriptions?.includes(spec?.id);

  return (
    <div
      key={spec.id}
      className={cn(
        styles.root,
        { [styles.rootDark]: dark },
        slicedBlock ? styles.sliced : null,
        className,
      )}
    >
      <div
        className={cn(styles.wrapperForm, { [styles.darkWrapperForm]: dark })}
      >
        <PreviewImage
          className={styles.wrapperImage}
          media={spec.mediaContentResponse}
          rootClassName={styles.img}
          showPreview
        />
        <div>
          <Link className={styles.nameBlock} to={`specialist/${spec.latinId}`}>
            <Typography
              type="title"
              className={cn({ [styles.nameBlockDark]: dark })}
            >
              {spec.firstName} {spec.secondName}
            </Typography>
          </Link>
          <SpecSpecialization
            dark={dark}
            className={styles.wrapperSpec}
            specialization={spec.specialities}
          />
        </div>
        <InfoSpecialist
          dark={dark}
          classname={styles.wrapperInfo}
          specialist={spec}
          showDuration={showDuration}
        />
        <div className={cn(styles.aboutSpec, styles.wrapperSpec)}>
          {showDescription && spec?.description && (
            <SpecDescription description={spec?.description?.trim()} />
          )}
          {showBriefAboutYourself && spec?.briefAboutYourself && (
            <SpecDescription
              description={spec?.briefAboutYourself?.trim()}
              label="НАПРАВЛЕНИЯ РАБОТЫ"
            />
          )}
          {showWorkMethods && workMethods && (
            <SpecDescription description={workMethods} label="МЕТОДЫ РАБОТЫ" />
          )}
        </div>
        <Slots
          dark={dark}
          slots={spec.slots}
          spec={spec}
          className={styles.wrapperSlot}
          onChange={changeValue => {
            onSubmit(changeValue);
          }}
          label="ВСЕ СЛОТЫ"
          pageType={pageType}
        />
        <SpecialistNavigate
          className={cn(styles.wrapperBtns, { [styles.darkWrapperBtns]: dark })}
          id={spec.latinId}
          specId={spec.id}
          specName={`${spec.firstName} ${spec.secondName}`}
          dark={dark}
          slots={spec.slots}
          handleReserve={onSubmit}
          isSubscribed={isSubscribed}
          pageType={pageType}
        />
      </div>
    </div>
  );
};

export default SpecialistCard;
