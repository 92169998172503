import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Modal } from 'antd';

import useChangeLocalSubscribeStatus from '../../../1_shared/api/hooks/useChangeLocalSubscribeStatus';
import { useSubscriptions } from '../../../1_shared/api/hooks/useNotificationSubcribe';
import { EMessageType } from '../../../1_shared/constants/EMessageType';
import { SpecSubContext } from '../../../1_shared/helpers/SpecSubContext';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { LoginForm } from '../../index';

import { ILoginModal } from './interfaces/ILoginModal';

import styles from './LoginModal.module.scss';
import { getUtm } from '../../../1_shared/helpers/getUtm';

const LoginModal = (props: ILoginModal) => {
  const navigate = useNavigate();

  const { showLoginForm = true } = props;
  const { specId, userMessageType } = useContext(SpecSubContext);
  const { subscribe, getSubscribes } = useSubscriptions();
  const { subscribeOnSpec } = useChangeLocalSubscribeStatus();
  const { user } = useAuthContext();

  const [reset, setReset] = useState<any>();

  const updateUSerSubscriptions = async (userId?: string) => {
    const userIdentification = userId ?? user?.userId;
    if (userIdentification) {
      localStorage.setItem(
        'subscriptions',
        JSON.stringify(await getSubscribes(userIdentification)),
      );
    }
  };

  const cancelHandler = async () => {
    if (reset) {
      reset();
    }

    await subscribe({
      userContactType: userMessageType ?? EMessageType.PHONE,
      subType: 'SUB_AVAILABLE_SLOTS',
      targetSpecId: specId,
      utm: getUtm(),
    })
      .then(() => {
        subscribeOnSpec(specId);
        navigate('#specSubscription');
      })
      .catch(error => {
        if (error.status === 400) navigate('#specSubscription');
      });
  };

  useEffect(() => {
    (async () => {
      await updateUSerSubscriptions(
        JSON.parse(localStorage.getItem('user') || '{}')?.userId,
      );
    })();
  }, [localStorage.getItem('user')]);

  return (
    <Modal className={styles.modal} {...props}>
      <div className={styles.root}>
        <LoginForm
          setReset={setReset}
          isClient
          isNeedRedirect={false}
          onClose={cancelHandler}
          showLoginForm={showLoginForm}
        />
      </div>
    </Modal>
  );
};

export default LoginModal;
