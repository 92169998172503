import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import { ELoginStatus } from '../../../1_shared/config/enums/ELoginStatus';
import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { RoutePath } from '../../../1_shared/config/routes';
import { EMessageType } from '../../../1_shared/constants/EMessageType';
import { phoneConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';
import { changeNickName } from '../../OrderForm/ui/api/order.login.service';
import { isPhoneField } from '../lib/checkPhone';

import {
  checkToken,
  sendOtpCode,
  signUp,
  updateUserData,
} from './api/auth.service';
import { IArgReg } from './intreface/reg/IArgReg';
import { IFirstRegInput } from './intreface/reg/IFirstRegInput';
// eslint-disable-next-line import/no-cycle
import { IHookRegOutput } from './intreface/reg/IHookRegOutput';
import { ISendAuthorizeData } from './useSubmit';

const useRegister = ({
  status,
  setLoginStatus,
  isPasswordPage,
  jwt,
  onClose,
}: IArgReg): IHookRegOutput => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login: loginAuth } = useAuthContext();

  const { data: codeData, trigger: sendCodeTr } = useSWRMutation(
    '/aim/sessions/request-code/first-time',
    sendOtpCode,
  );

  const { trigger: refreshUserData } = useSWRMutation(
    '/aim/api/v1/user-data/refresh',
    updateUserData,
  );

  const { trigger: check, error: errorToken } = useSWRMutation(
    '/aim/check/token',
    checkToken,
  );

  const { data: newUserData, trigger: signUpTr } = useSWRMutation(
    'aim/api/v2/signUp',
    signUp,
  );

  const { trigger: nicknamePatch } = useSWRMutation(
    '/aim/v1/user/nickname',
    changeNickName,
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const sendCode = async (data: Partial<ISendAuthorizeData>) => {
    try {
      await sendCodeTr({
        contactInfo: phoneConvert(String(data.login)),
        contactType: data?.contactType ?? EMessageType.PHONE,
        // role: data.role as ERoles,
      });
      setLoginStatus(ELoginStatus.CodeSend);
    } catch (e) {
      if (e) {
        setErrorMessage((e as any).response.data.message);
      }
    }
  };

  const updateNickname = async (name: string) => {
    await nicknamePatch({ nickname: name });
    if (loginAuth) {
      loginAuth(ERoles.Client);
    }
  };

  const regWithCode = async (
    data: Partial<ISendAuthorizeData>,
    isNeedRedirect = true,
    customRoute = null,
    // eslint-disable-next-line consistent-return
  ) => {
    const tempData: IFirstRegInput = {
      utm: data.utm || undefined,
      phone: isPhoneField(data.login) ? phoneConvert(data.login) : null,
      email: !isPhoneField(data.login) ? data.login : null,
      // contactType: isPhoneField(data.login)
      //   ? EMessageType.PHONE
      //   : EMessageType.EMAIL,
      nickname: data?.nickname || null,
      firstName: data?.firstName || null,
      surname: data?.surname || null,
      patronymic: data?.patronymic || null,
      code: data.code,
      roles: codeData || (data.role as ERoles) || ERoles.Client,
      password: data?.password,
      passwordConfirmation: data?.passwordConfirmation,
    };

    localStorage.setItem(
      'role',
      codeData || (data.role as ERoles) || ERoles.Client,
    );
    await signUpTr(tempData);

    /** Yandex.Metrics */
    // @ts-ignore
    ym(97788822, 'reachGoal', 'userreg', {
      isAuthorize: false,
      regType:
        (data?.role as ERoles) === ERoles.Spec || (codeData && codeData !== '')
          ? 'spec'
          : 'client',
      userId: newUserData?.userId,
    });

    setLoginStatus(ELoginStatus.None);
    if (data.role === ERoles.Client) {
      await updateNickname(tempData?.nickname || '');
      await refreshUserData(null);
    }
    if (loginAuth) {
      loginAuth(String(data.role).toUpperCase() as ERoles);
      if (location.pathname !== RoutePath.ORDER && isNeedRedirect) {
        if (customRoute) return navigate(customRoute);
        navigate(RoutePath.MAIN);
      } else {
        onClose(null);
      }
    }
  };

  const register = async (
    data: Partial<ISendAuthorizeData>,
    isNeedRedirect?: boolean,
    customRoute?: any,
  ) => {
    if (status === ELoginStatus.CodeSend) {
      await regWithCode(data, isNeedRedirect, customRoute);
    } else {
      await sendCode(data);
    }
  };

  useEffect(() => {
    if (jwt && isPasswordPage) {
      (async () => await check({ token: jwt }))();
    }
  }, [check, jwt, isPasswordPage]);

  useEffect(() => {
    if (errorToken) {
      navigate(RoutePath.LOGIN);
    }
  }, [errorToken]);

  return {
    register,
    errorMessage,
    setErrorMessage,
  };
};

export default useRegister;
