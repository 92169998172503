import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import useSWRMutation from 'swr/mutation';

import { ESpecStatus } from '1_shared/config/enums/ESpecStatus';
import { RoutePath } from '1_shared/config/routes';
import { profileSpec } from '4_widgets/LoginForm/model/api/auth.service';

import { specApiInstance } from '../../../../1_shared/api/apiInstance';
import { ERoles } from '../../../../1_shared/config/enums/ERoles';
import { ISpecialistByIdData } from '../../../../1_shared/config/interfaces/ISpecialistByIdData';
import { ISpecialistData } from '../../../../1_shared/config/interfaces/ISpecialistData';
import { IClientPreviewOutput } from '../../../api/interfaces/IClientPreviewOutput';
import { getProfileUrl } from '../../../../1_shared/helpers/getProfileUrl';

export const useAuth = (): {
  user: (IClientPreviewOutput & ISpecialistData & ISpecialistByIdData) | null;
  role: ERoles;
  logout: () => Promise<void>;
  login: (roles: ERoles) => void;
  setSpecUser: (
    spec: (IClientPreviewOutput & ISpecialistData & ISpecialistByIdData) | null,
  ) => void;
  clearUser: () => void;
} => {
  const [roleState, setRoleState] = useState<ERoles | null>(null);

  const userObject = !localStorage?.getItem('user')
    ? JSON.parse(localStorage.getItem('user') || '{}')
    : null;

  const [user, setUser] = useState<
    IClientPreviewOutput | ISpecialistData | ISpecialistByIdData | null
  >(userObject);

  const clearUser = () => {
    setUser(null);
    setRoleState(null);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const { trigger: checkProfile } = useSWRMutation(
    getProfileUrl(ERoles.Spec),
    profileSpec,
    {
      onSuccess: data => {
        setUser(data);
        localStorage.setItem('user', JSON.stringify(data));

        if (data.status === ESpecStatus.NotActive) {
          navigate(RoutePath.FIRST_FORM);
        }

        if (
          data.status !== ESpecStatus.NotActive &&
          location.pathname === RoutePath.FIRST_FORM
        ) {
          navigate(RoutePath.SPECIALIST_CABINET);
        }
      },
    },
  );

  /**
   * @implements {ERoles} role кладется в LS
   * @implements {Array} promoCodes запрашивается при входе, обновляется только при оформлении заказа или при инициализации нового промокода
   * @implements {Array} subscriptions запрашивается при входе через модальное окно и при каждом открытии каталога специалистов
   * @implements {Object} user инициализируется при входе в приложение, в данный момент вызывается дважды (login и в apiInstance)
   * */
  const login = async (roles: ERoles) => {
    const role = roles ?? (localStorage.getItem('role') as ERoles);
    setRoleState(role);

    const reqProfile = await specApiInstance.get(getProfileUrl(role));
    setUser(reqProfile.data);
    localStorage.setItem('user', JSON.stringify(reqProfile.data));

    const promoCodes = await specApiInstance.get(
      `ls/api/v2/promocodes/byUserId?userId=${reqProfile.data?.userId}`,
    );

    delete reqProfile.data.slots;
    if (promoCodes?.data) {
      localStorage.setItem('promoCodes', JSON.stringify(promoCodes?.data));
    }
  };

  const logout = async () => {
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('promoCodes');
    localStorage.removeItem('subscriptions');
    localStorage.removeItem('subscribeSlotData');
    setUser(null);
    setRoleState(null);
    await specApiInstance.post('aim/logout');
    // window.location.reload();
  };

  const setSpecUser = (
    spec: IClientPreviewOutput | ISpecialistData | ISpecialistByIdData | null,
  ) => {
    setUser(spec);
    localStorage.setItem('user', JSON.stringify(spec));
  };

  const checkProfileStatus = useCallback(async () => {
    await checkProfile();
  }, [navigate]);

  useEffect(() => {
    if (roleState === ERoles.Spec) {
      checkProfileStatus();
    }
  }, [roleState, checkProfileStatus]);

  useEffect(() => {
    const getItem =
      !localStorage?.getItem('user') ||
      localStorage?.getItem('user') === 'undefined'
        ? null
        : localStorage?.getItem('user');
    const userLS = !getItem ? null : JSON.parse(`${getItem}`);
    if (userLS) {
      setUser(userLS);
      const role = userLS?.role ?? localStorage.getItem('role') ?? null;
      if (role) {
        setRoleState(role.toUpperCase());
      }
    }
  }, []);

  // TODO: deprecate if not needed
  // useEffect(() => {
  //   if (!Cookies.get('user.data') && !!user) {
  //     console.log('not found cookies', Cookies, user);
  //     logout();
  //     navigate('/');
  //   }
  // }, []);

  return {
    user: user as IClientPreviewOutput & ISpecialistData & ISpecialistByIdData,
    role: roleState as ERoles,
    logout,
    login,
    setSpecUser,
    clearUser,
  };
};
