import { Dispatch, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { EYandexPage } from '../../../../../../1_shared/api/services/configs/interfaces/EYandexConfigs';
import { DurationType } from '../../../../../../1_shared/config/interfaces/DurationType';
import { ISpecialistByIdData } from '../../../../../../1_shared/config/interfaces/ISpecialistByIdData';
import { timeConfig } from '../../../../../../1_shared/config/timeConfig';
import { Typography } from '../../../../../../1_shared/ui';
import { SlotSelect } from '../../../../../../3_features';
import Slots from '../../../../../../3_features/Slots/ui/Slots';
import { IPromoCodeOutput } from '../../../../model/api/interfaces/IPromoCodeOutput';

import styles from '../../../OrderForm.module.scss';

const OrderFormSpecialistSlotSelect = ({
  spec,
  setPromoCode,
  disabled = false,
}: {
  spec?: ISpecialistByIdData;
  setPromoCode: Dispatch<SetStateAction<IPromoCodeOutput | undefined>>;
  disabled?: boolean;
}) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name="slotId"
      render={({ field }) => {
        const duration = spec?.slots?.find(
          el => el.slotId === field.value,
        )?.duration;
        return (
          <div className={styles.controllerContainer}>
            <div className={cn(styles.row, styles.specialistField)}>
              <Typography type="subtitle" className={styles.rowText}>
                ДАТА И ВРЕМЯ
              </Typography>
              <SlotSelect
                value={field.value}
                spec={spec}
                onChange={elem => {
                  setValue('slotId', elem);
                  setPromoCode(undefined);
                }}
                disabled={disabled}
              />
              {duration && (
                <Typography
                  type="description"
                  className={styles.descriptionFontSize}
                >
                  {`Длительность сеанса ${timeConfig[duration as DurationType]}`}
                </Typography>
              )}
            </div>
            <Slots
              spec={spec}
              slots={spec?.slots || []}
              className={styles.wrapperSlot}
              onChange={elem => {
                setValue('slotId', elem?.slotId);
                setPromoCode(undefined);
              }}
              label="ВСЕ СЛОТЫ"
              pageType={EYandexPage.Order}
            />
          </div>
        );
      }}
    />
  );
};

export default OrderFormSpecialistSlotSelect;
