import { RoutePath } from '../../../../1_shared/config/routes';
import { IPanelBtnsConfig } from '../interfaces/panelBtnsConfig.interface';

export const PanelBtnsConfig = ({
  navigate,
  setModalOpen,
}: IPanelBtnsConfig) => [
  {
    label: 'Создать сеанс',
    action: () => setModalOpen(true),
  },
  {
    label: 'Посмотреть созданные сеансы',
    action: () => navigate(RoutePath.ADMIN_SESSIONS_LIST),
  },
];
