import { Dispatch, ReactElement, SetStateAction } from 'react';
import { Spin } from 'antd';
import cn from 'classnames';

import { ISpecialistByIdData } from '../../../../1_shared/config/interfaces/ISpecialistByIdData';
import { Typography } from '../../../../1_shared/ui';
import { SpecialistAvatar } from '../../../../2_entities';
import { IPromoCodeOutput } from '../../model/api/interfaces/IPromoCodeOutput';

import OrderFormSpecialistSlotSelect from './OrderFormSpecialistField/ui/OrderFormSpecialistSlotSelect';

import styles from '../OrderForm.module.scss';

interface IOrderFormSpecialistField {
  isLoading: boolean;
  avatar: string;
  setPromoCode: Dispatch<SetStateAction<IPromoCodeOutput | undefined>>;
  disabled?: boolean;
  spec?: ISpecialistByIdData;
  customCreateSession?: boolean;
}

/**
 * Поле для отображения специалиста к которому оформляется заявка на сессию и выбор доступного
 * */
const OrderFormSpecialistField = (
  props: IOrderFormSpecialistField,
): ReactElement => {
  const {
    isLoading,
    avatar,
    setPromoCode,
    disabled = false,
    spec,
    customCreateSession,
  } = props;

  return (
    <>
      <div className={cn(styles.row)}>
        <Typography type="subtitle" className={styles.rowText}>
          СПЕЦИАЛИСТ
        </Typography>
        {isLoading ? (
          <Spin />
        ) : (
          <SpecialistAvatar
            firstName={String(spec?.firstName)}
            lastName={String(spec?.secondName)}
            imgPreview={avatar}
          />
        )}
      </div>
      <OrderFormSpecialistSlotSelect
        spec={spec}
        setPromoCode={setPromoCode}
        disabled={customCreateSession ? false : disabled}
      />
    </>
  );
};

export default OrderFormSpecialistField;
