import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import cn from 'classnames';
import { isArray } from 'lodash';

import { Typography } from '../../1_shared/ui';
import { useAuthContext } from '../../app/module/lib/hooks/useAuthContext';

import { cardLinks } from './constants/cardLinks';
import { TTab } from './types/TTab';
import SpecialistEditProfileCredentials from './ui/Credentials/SpecialistEditProfileCredentials';
import SpecialistEditProfileEducation from './ui/Education/SpecialistEditProfileEducation';
import SpecialistEditProfileMainInfo from './ui/MainInfo/SpecialistEditProfileMainInfo';
import OtherInformation from './ui/OtherInformation/OtherInformation';
import ProgressBar from './ui/ProgressBar/ProgressBar';
import SpecialistEditProfileWorkExperience from './ui/WorkExperience/SpecialistEditProfileWorkExperience';

import styles from './SpecialistEditProfile.module.scss';

const SpecialistEditProfile = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  // TODO: change string -> links of cards
  const [showingTab, setShowingTab] = useState<TTab>('credentials');
  useEffect(() => {
    const metaTag = document.querySelector("meta[name='theme-color']");
    const previousColor = metaTag?.getAttribute('content');

    if (metaTag) {
      metaTag.setAttribute('content', '#ffffff');
    }

    return () => {
      if (metaTag && previousColor) {
        metaTag.setAttribute('content', previousColor);
      }
    };
  }, []);

  const cardSelector = useMemo(() => {
    switch (showingTab) {
      case 'mainInfo':
        return <SpecialistEditProfileMainInfo />;
      case 'workExperience':
        return <SpecialistEditProfileWorkExperience />;
      case 'education':
        return <SpecialistEditProfileEducation />;
      case 'otherInformation':
        return <OtherInformation />;
      case 'credentials':
      default:
        return <SpecialistEditProfileCredentials />;
    }
  }, [showingTab]);

  const linkSelector = useMemo(
    () =>
      cardLinks.map(link => (
        <Button
          type="text"
          className={cn(styles.button, {
            [styles.buttonSelected]: link.name === showingTab,
          })}
          onClick={() => setShowingTab(link.name)}
        >
          {link.label}
        </Button>
      )),
    [showingTab],
  );

  const calculateProgress = useMemo(
    () =>
      user
        ? Math.ceil(
            (Object.values(user).filter(el => (isArray(el) ? !!el?.length : el))
              .length /
              Object.values(user).length) *
              100,
          )
        : 0,
    [user],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.leftBlock}>
          <Card>
            <div className={styles.progressCard}>
              <Typography type="title">Профиль заполнен</Typography>
              <ProgressBar progress={calculateProgress} />
              <Typography>
                При заполнении анкеты более 85%, больше шансов, что выберут вас
              </Typography>
            </div>
          </Card>
          <Card size="small">
            <div className={styles.linkSelector}>{linkSelector}</div>
          </Card>
        </div>
        <Card className={styles.infoWrapper}>{cardSelector}</Card>
      </div>
      <Button
        className={styles.closeButton}
        onClick={() => navigate('/pc/spec')}
        type="text"
      >
        <CloseOutlined />
      </Button>
    </div>
  );
};

export default SpecialistEditProfile;
