import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import { RoutePath } from '1_shared/config/routes';
import { Page } from '1_shared/ui';

import { specApiInstance } from '../../1_shared/api/apiInstance';
import LogoSvg from '../../1_shared/assets/pictures/logo.svg';
import LogoMobileSvg from '../../1_shared/assets/pictures/logo-mobile.svg';
import LogoMIPSvg from '../../1_shared/assets/pictures/MIPv2.svg';
import { ERoles } from '../../1_shared/config/enums/ERoles';
import { EMessageType } from '../../1_shared/constants/EMessageType';
import { UserRoutes } from '../../1_shared/constants/userRoutes';
import { useAuthContext } from '../../app/module/lib/hooks/useAuthContext';

import styles from './Oauth.module.scss';

/** Страница для проверки входа по SSO */
const Oauth = () => {
  const location = useLocation();
  const { state } = location as {
    state: { pathname?: string; state: unknown };
  };
  const navigate = useNavigate();
  const { login, user } = useAuthContext();

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const stateParam = searchParams.get('state');

  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [percent, setPercent] = useState(-50);
  const [auto] = useState(false);
  const mergedPercent = auto ? 'auto' : percent;

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setPercent(v => {
        const nextPercent = v + 2;
        return nextPercent > 150 ? -50 : nextPercent;
      });
    }, 100);
    return () => clearTimeout(timerRef.current);
  }, [percent]);

  const fetchData = async () => {
    try {
      const requestInstudy: any = await specApiInstance.get(
        '/aim/login/oauth2/code/instudy',
        {
          params: {
            code,
            state: stateParam,
          },
        },
      );

      /**
       * Проверка, что ответ от бэка корректный
       * Ответ может прийти некорректный поскольку не реализована возможность входа пользователя с ролью "Специалист"
       * */
      if (requestInstudy) {
        if (requestInstudy?.response?.data?.message) {
          throw new Error(requestInstudy?.response?.data);
        }
        if (requestInstudy?.data?.roles) {
          localStorage.setItem('role', requestInstudy?.data?.roles);

          if (login) {
            login(requestInstudy.data?.roles);
          }

          /** Организация подписки после входа по SSO */
          const subscribeSlotData = {
            ...JSON.parse(localStorage.getItem('subscribeSlotData') || '{}'),
            userMessageType: user?.email
              ? EMessageType.EMAIL
              : EMessageType.PHONE,
          };
          if (subscribeSlotData?.needSlotSubscribeAfterLogin) {
            localStorage.removeItem('subscribeSlotData');
            navigate('/specialists', {
              state: { ...(state || {}), ...subscribeSlotData },
            });
            return;
          }

          /** Организация перехода на страницу заказа после входа по SSO */
          const ssoOrderPageLoginData = JSON.parse(
            localStorage.getItem('ssoOrderPageLoginData') || '{}',
          );
          console.log(ssoOrderPageLoginData);
          if (ssoOrderPageLoginData?.needRedirectToOrderPage) {
            localStorage.removeItem('ssoOrderPageLoginData');
            navigate('/order', {
              state: {
                ...(state || {}),
                ...(ssoOrderPageLoginData?.state || {}),
              },
            });
            return;
          }

          /** Редирект на страницу профиля при обычной авторизации пользователя */
          navigate(UserRoutes[requestInstudy.data.roles as ERoles], {
            state: { ...(state?.state || {}), needReload: true },
          });
        }
      }
    } catch (error: any) {
      console.error('oauth', error);
      navigate('/login', {
        state: {
          ...state,
          content:
            error?.message ||
            'Возникла ошибка при попытке авторизации через InStudy',
        },
      });
    }
  };

  useEffect(() => {
    if (!code || !stateParam) {
      navigate('/login', {
        state: {
          content: 'Ошибка получения данных при авторизации через InStudy',
        },
      });
      return;
    }
    fetchData();
  }, []);

  return (
    <Page>
      <div className={styles.root}>
        <div>
          <div className={styles.wrapperLogos}>
            <Link to={RoutePath.MAIN}>
              <source media="(max-width: 1023px)" srcSet={LogoMobileSvg} />
              <img src={LogoSvg} alt="DoTherapy" width="200" height="39" />
            </Link>
            <a
              href="https://inpsycho.ru"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.mipLogo}
            >
              <source media="(max-width: 1023px)" srcSet={LogoMIPSvg} />
              <img src={LogoMIPSvg} alt="DoTherapy" width="200" height="39" />
            </a>
          </div>
        </div>
        <div className={styles.wrapper}>
          <Spin percent={auto ? 'auto' : mergedPercent} size="large" />
          Проверяем авторизацию через сервис InStudy...
        </div>
      </div>
    </Page>
  );
};

export default Oauth;
