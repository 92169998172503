import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from 'antd';
import cn from 'classnames';

import ArrowSSO from '../../../../1_shared/assets/pictures/arrowSSO.svg';
import TelegramIcon from '../../../../1_shared/assets/pictures/telegram.svg';
import { apiLinksByEnv } from '../../../../1_shared/constants/apiLinksByEnv';
import { TelegramHelpLink } from '../../../../1_shared/constants/TelegramHelpLink';
import { Typography } from '../../../../1_shared/ui';
import { useAuthContext } from '../../../../app/module/lib/hooks/useAuthContext';
import { env } from '../../../../env';

import styles from '../OrderForm.module.scss';

const OrderFormHelperBlock = (): ReactElement => {
  const { pathname, state } = useLocation();
  const { user } = useAuthContext();

  const SSOOrderPageHandler = () => {
    localStorage.setItem(
      'ssoOrderPageLoginData',
      JSON.stringify({
        needRedirectToOrderPage: true,
        state,
      }),
    );
  };

  return (
    <div className={styles.helperBlock}>
      <Button
        className={styles.btnSSO}
        href={TelegramHelpLink}
        target="_blank"
        rel="noopener noreferrer"
        color="default"
        variant="link"
      >
        <Typography className={cn(styles.textInBtn, styles.helperText)}>
          <img src={TelegramIcon} alt="filter" className={styles.instudyIcon} />
          Нужна помощь?{' '}
          <Typography className={cn(styles.underline, styles.helperText)}>
            Напишите нам
          </Typography>
        </Typography>
      </Button>
      {(env.ALLOW_SSO === 'true' || env.NODE_ENV === 'development') &&
        !user?.id && (
          <Button
            className={styles.btnSSO}
            href={`${apiLinksByEnv}/aim/oauth2/authorization/instudy`}
            onClick={SSOOrderPageHandler}
            target="_self"
            rel="noopener noreferrer"
            color="default"
            variant="link"
          >
            <Typography className={cn(styles.textInBtn, styles.helperText)}>
              <img src={ArrowSSO} alt="filter" className={styles.instudyIcon} />
              Войти через{' '}
              <Typography className={cn(styles.underline, styles.helperText)}>
                InStudyID
              </Typography>
            </Typography>
          </Button>
        )}
    </div>
  );
};

export default OrderFormHelperBlock;
