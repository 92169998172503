import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input, Modal, Tooltip } from 'antd';

import {
  CreateSessionByAdmin,
  GetClientsByInfo,
  GetSpecByName,
  GetSpecInfoById,
  UpdateUserInfoByAdmin,
} from '../../../../../1_shared/api/services';
import { ICreateSessionByAdminRequest } from '../../../../../1_shared/api/services/configs/interfaces/adminService.interface';
import { Button, Typography } from '../../../../../1_shared/ui';
import useCreateOptions from '../../../../../3_features/SlotSelect/model/useCreateOptions';
import usePayment from '../../../../OrderForm/model/usePayment';

import FieldSelector from './CreateSessionFields/FieldSelector';
import PromoCodeInputField from './CreateSessionFields/PromoCodeInputField';

import styles from './CreateSessionModal.module.scss';
import { formatedPrice } from '../../../../../1_shared/helpers/formatedPrice';
import PromoCodeDescription from '../../../../OrderForm/ui/OrderFormComponents/OrderFormPromoCodeField/PromoCodeDescription';
import { EItemCampaignIdentificators } from '../../../../../1_shared/constants/promoCampaigns/NewYear2024';
import { ICreateSessionModal } from '../../../model/interfaces/createSessionModal.interface';
import {
  createSessionByAdminSchema,
  TSessionByAdminSchema,
} from '../../../model/config/createSessionByAdminSchema';
import { TCreateSessionByAdmin } from '../../../model/types/createSessionByAdmin.type';
import { debounce } from 'lodash';
import { ERoles } from '../../../../../1_shared/config/enums/ERoles';
import { CheckCircleFilled } from '@ant-design/icons';
import { EMessageType } from '../../../../../1_shared/constants/EMessageType';
import {
  phoneConvert,
  phoneFormConvert,
} from '../../../../../1_shared/lib/helpers/phoneConvert';
import useMessage from 'antd/es/message/useMessage';

const CreateSessionModal = (props: ICreateSessionModal): ReactElement => {
  const [messageApi, contextHolder] = useMessage();

  const methods = useForm<TSessionByAdminSchema>({
    resolver: zodResolver(createSessionByAdminSchema),
  });
  const { handleSubmit, getValues, watch, reset, setValue } = methods;
  watch(['specUserId', 'slotId', 'clientUserId', 'userInfoForUpdate']);

  const [infoWasUpdated, setInfoWasUpdated] = useState(false);
  const [orderDone, setOrderDone] = useState(false);
  const [specSearch, setSpecSearch] = useState('а');
  const [clientSearch, setClientSearch] = useState('');

  /* Services */
  const { specs, specsLoading } = GetSpecByName(specSearch);
  const { slots, slotsLoading } = GetSpecInfoById(getValues('specUserId'));
  const { clients, clientsLoading } = GetClientsByInfo(clientSearch);
  const { createSession, response } = CreateSessionByAdmin();
  const { updateUser, updatingUser } = UpdateUserInfoByAdmin();
  /* Services */

  /* Состояния и методы для полей апдейта информации о клиенте*/
  const [client, setClient] = useState(null);
  const isClientHasPhone = !!(client as any)?.phone;
  const isClientHasEmail = !!(client as any)?.email;
  const [updateClientInfo, setUpdateClientInfo] = useState(false);

  const updateUserHandler = (type: EMessageType) => {
    const isUpdatePhone =
      type === EMessageType.PHONE &&
      /* проверка инпута на то что передана валидная строка которая соберется в номер телефона +79%userInput% */
      Number(getValues('userInfoForUpdate')?.length) === 9;
    if (isUpdatePhone || type === EMessageType.EMAIL) {
      updateUser({
        userId: getValues('clientUserId') ?? '',
        contactInfoType: type,
        contactInfo: isUpdatePhone
          ? phoneConvert(
              phoneFormConvert(`+7${getValues('userInfoForUpdate')}`),
            )
          : getValues('userInfoForUpdate'),
      })
        .then(() => {
          setValue('userInfoForUpdate', '');
          setInfoWasUpdated(true);
        })
        .catch(() => setInfoWasUpdated(false))
        .finally(() => setUpdateClientInfo(false));
    }
  };
  /* Состояния и методы для полей апдейта информации о клиенте*/

  const {
    onPromoCodeFind,
    promoCode,
    isPromoLoading,
    errorPromo,
    setPromoCode,
    setErrorPromo,
  } = usePayment();

  const cost = useMemo(
    () => slots?.find(slot => slot.slotId === getValues('slotId'))?.cost,
    [slots, getValues('slotId')],
  );

  const onSubmit = (data: FieldValues) => {
    const request: ICreateSessionByAdminRequest = {
      clientUserId: data.clientUserId,
      specialistUserId: data.specUserId,
      slotId: data.slotId,
      promoCode: data.promoCode || null,
      price: cost || 0,
      discount: promoCode?.discount || 0,
      discountedPrice:
        Number(cost) - Number(promoCode?.discount ?? 0) ||
        Number(promoCode?.discounted_price) ||
        0,
    };
    createSession(request)
      .then(() => {
        setOrderDone(true);
        messageApi.success({
          content: `Заявка на сессию создана успешно! Клиенту отправлена ссылка для оплаты`,
          duration: 5,
        });
      })
      .catch(error => {
        messageApi.error({
          content: `При попытке создать заявку произошла ошибка - ${error}`,
          duration: 5,
        });
      });
  };

  const handleChange = useMemo(
    () =>
      debounce((value: string | null, role: ERoles, clearFields = false) => {
        const callback = role === ERoles.Spec ? setSpecSearch : setClientSearch;
        if (value && value.length > 1) callback(value);
        if (clearFields) {
          setPromoCode(undefined);
        }
      }, 500),
    [],
  );

  const onCancel = useCallback((event: any) => {
    setOrderDone(false);
    onClearForm();
    if (props?.onCancel) props.onCancel(event);
  }, []);

  const onClearForm = () => {
    setOrderDone(false);
    handleChange(null, ERoles.Spec, true);
    setSpecSearch('а');
    setClientSearch('');
    reset();
  };

  useEffect(() => {
    return () => {
      handleChange.cancel();
    };
  }, [handleChange]);

  return (
    <Modal className={styles.modal} {...props} onCancel={onCancel}>
      <FormProvider {...methods}>
        <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
          <Typography type="h3">Создать сеанс</Typography>
          <div className={styles.form}>
            <FieldSelector<TCreateSessionByAdmin>
              name="specUserId"
              label="Специалист"
              options={specs}
              placeholder="Начните вводить имя специалиста"
              showSearch
              loading={specsLoading}
              onSearch={value => handleChange(value, ERoles.Spec, true)}
              onChange={() => {
                setValue('clientUserId', null);
                setValue('slotId', null);
              }}
              disabled={orderDone}
            />
            <FieldSelector<TCreateSessionByAdmin>
              name="slotId"
              label="Слот"
              options={useCreateOptions(slots || []).options}
              placeholder="Выберите слот для сессии"
              disabled={!getValues('specUserId') || orderDone}
              loading={slotsLoading}
            />
            <div className={styles.clientBlock}>
              <FieldSelector<TCreateSessionByAdmin>
                name="clientUserId"
                label="Клиент"
                placeholder="Начните вводить данные клиента"
                loading={clientsLoading}
                options={clients}
                showSearch
                disabled={!getValues('slotId') || orderDone}
                onSearch={value => handleChange(value, ERoles.Client)}
                onChange={option => {
                  setClient(option);
                  setUpdateClientInfo(false);
                  setInfoWasUpdated(false);
                }}
              />
              {updateClientInfo && (
                <Controller
                  control={methods.control}
                  name={'userInfoForUpdate'}
                  render={({ field: { onChange } }) => (
                    <Input
                      onChange={onChange}
                      placeholder={`Напишите ${!isClientHasPhone ? 'телефон' : 'e-mail'}`}
                      prefix={!isClientHasPhone && '+7'}
                      maxLength={!isClientHasPhone ? 10 : 64}
                      suffix={
                        <Tooltip
                          title={`Подтвердить добавление ${!isClientHasPhone ? 'телефона' : 'e-mail'}`}
                        >
                          <CheckCircleFilled
                            onClick={() =>
                              updateUserHandler(
                                isClientHasPhone
                                  ? EMessageType.EMAIL
                                  : EMessageType.PHONE,
                              )
                            }
                          />
                        </Tooltip>
                      }
                    />
                  )}
                />
              )}
              {getValues('clientUserId') &&
                (!isClientHasPhone || !isClientHasEmail) &&
                !infoWasUpdated && (
                  <Button
                    onClick={() => setUpdateClientInfo(prevState => !prevState)}
                  >
                    {!updateClientInfo ? 'Добавить' : 'Отменить добавление'}{' '}
                    {!isClientHasPhone ? `телефон` : `e-mail`}
                    {updateClientInfo && 'а'}
                  </Button>
                )}
            </div>
            <PromoCodeInputField<TCreateSessionByAdmin>
              name="promoCode"
              slotId={getValues('slotId') ?? ''}
              userId={getValues('clientUserId') ?? ''}
              promoCode={promoCode}
              onPromoCodeFind={onPromoCodeFind}
              setPromoCode={setPromoCode}
              isPromoLoading={isPromoLoading}
              isDisabled={!getValues('clientUserId') || orderDone}
              errorPromo={errorPromo}
              setErrorPromo={setErrorPromo}
              customCreateSession
            />
            <div className={styles.costWrapper}>
              {getValues('slotId') && (
                <div className={styles.priceContainer}>
                  <Typography type="title">
                    {`Стоимость сеанса: ${formatedPrice(
                      promoCode && getValues('slotId')
                        ? promoCode.discounted_price
                        : cost || 0,
                    )}`}
                  </Typography>
                  {getValues('promoCode') && (
                    <PromoCodeDescription
                      discountTitle={`Скидка составит`}
                      promoCode={
                        promoCode || {
                          discount: 0,
                          type: EItemCampaignIdentificators.STANDART,
                        }
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              htmlType="submit"
              type="primary"
              className={styles.submitButton}
              onClick={() => {
                if (orderDone) onClearForm();
              }}
            >
              {orderDone ? 'Cоздать еще один сеанс' : 'Создать сеанс'}
            </Button>
            <Button type="text" onClick={onClearForm}>
              Очистить форму
            </Button>
          </div>
        </form>
      </FormProvider>
      {contextHolder}
    </Modal>
  );
};

export default CreateSessionModal;
