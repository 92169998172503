import { ReactElement, useEffect, useState } from 'react';
import { Table } from 'antd';

import { ESlotStatus } from '../../../1_shared/config/enums/ESlotStatus';
import { BackButton, Typography } from '../../../1_shared/ui';

import styles from './AdminSessions.module.scss';
import {
  CancelOrderByAdmin,
  GetAllSessionsByAdmin,
} from '../../../1_shared/api/services';
import { ISessionsByAdminResponse } from '../../../1_shared/api/services/configs/interfaces/adminService.interface';
import { RoutePath } from '../../../1_shared/config/routes';
import useAdminsSessionsColumnsConfig from '../config/useAdminsSessionsColumnsConfig';

const AdminSessionsList = (): ReactElement => {
  // Присвоение класса для строки таблицы, которая не была оплачена
  const getRowPaymentStatus = (record: ISessionsByAdminResponse) =>
    record.confirmationStatus === ESlotStatus.WaitingForPay
      ? styles.notPaidRow
      : '';

  const [cancelSessionModal, setCancelSessionModal] = useState<{
    openModal: boolean;
    sessionId: string;
  }>({
    openModal: false,
    sessionId: '',
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // для определения вида устройства мобильный/нет
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1023);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { sessions, isSessionsLoading, mutate } = GetAllSessionsByAdmin();

  const { cancelOrder, isOrderCanceling } = CancelOrderByAdmin();

  const cancelHandler = (id: string) => {
    cancelOrder({ sessionId: id }).then(() => mutate());
  };

  const { adminsSessionsColumnsConfig } =
    useAdminsSessionsColumnsConfig(cancelHandler);

  return (
    <section className={styles.container}>
      <BackButton pathTo={RoutePath.ADMIN_CABINET} />
      <Typography type="title" component="h1">
        Сеансы:
      </Typography>
      <Table<ISessionsByAdminResponse>
        pagination={{
          position: !isMobile ? ['bottomRight'] : ['bottomCenter'],
        }}
        size={isMobile ? 'small' : 'large'}
        columns={adminsSessionsColumnsConfig(isMobile)}
        dataSource={sessions}
        rowClassName={getRowPaymentStatus}
        scroll={{ x: 'max-content', y: 'max-content' }}
      />
    </section>
  );
};

export default AdminSessionsList;
