import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import App from './app/App';
import {
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';

init({
  dsn: 'https://9e706ee4267120345eec3c231fa6fea9@sentry.dtechs.dev/9',

  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    replayIntegration(),
  ],

  tracesSampleRate: 1.0,

  tracePropagationTargets: ['localhost', 'https://dot.dtechs.dev'],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
