import { Link } from 'react-router-dom';

import { RoutePath } from '../../../config/routes';

import 'app/styles/global/global.scss';
import './survey.scss';
import { Typography } from '../../index';

const SurveyDiscount = () => (
  <section className="survey" id="survey">
    <div className="container survey__container">
      <div className="survey__inner__black">
        <div className="survey__heading">
          <h2 className="survey__title section-title">Скидка 20%</h2>
          <Typography type="p" className="survey__subtitle">
            по промокоду NEW20 для новых пользователей на первую сессию
          </Typography>
        </div>
        <div className="survey__btn-wrap">
          <Link
            to={RoutePath.SPECIALISTS}
            state={{ anchor: '#discount' }}
            className="survey__btn btn btn--violet btn--survay-discount-btn"
          >
            Подобрать специалиста
          </Link>
          от 2 360₽ за сессию
        </div>
      </div>
    </div>
  </section>
);

export default SurveyDiscount;
