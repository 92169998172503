import { ReactElement, useState } from 'react';
import { Image } from 'antd';
import cn from 'classnames';
import { ImageProps } from 'rc-image';

import DoTherapyLogo from '1_shared/assets/images/doTherapyLogo.png';

import { IPreviewImage } from './interfaces/IPreviewImage';

import styles from './PreviewImage.module.scss';

const PreviewImage = ({
  media,
  dark = false,
  noAvatar = false,
  className = '',
  showPreview = false,
  isMobile = false,
  ...others
}: IPreviewImage): ReactElement => {
  const previewLink =
    media?.previewLink || media?.mediaContentResponse?.previewLink;
  const [visible, setVisible] = useState(false);

  const preview: ImageProps['preview'] = isMobile
    ? {
        visible,
        onVisibleChange: value => setVisible(value),
        imageRender: originalNode => (
          <div onClick={() => setVisible(false)}>{originalNode}</div>
        ),
      }
    : showPreview;

  return (
    <div
      className={cn(styles.root, {
        [className]: className !== '',
        [styles.darkRoot]: dark,
        [styles.noAvatar]: noAvatar,
      })}
    >
      <Image
        className={cn(styles.antImage, {
          [styles.antImageLogo]: !previewLink,
          [styles.aspectRatio916]: previewLink,
        })}
        preview={preview}
        src={previewLink ?? DoTherapyLogo}
        style={
          !previewLink
            ? { width: 'inherit', borderRadius: '0 !important' }
            : undefined
        }
        {...others}
      />
    </div>
  );
};

export default PreviewImage;
