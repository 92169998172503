import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Tooltip } from 'antd/lib';

import { Input, Typography } from '../../../../../1_shared/ui';
import { IApplicationForm } from '../../interface/IApplicationForm';

import { IOrderFormPromoCodeField } from './model/IOrderFormPromoCodeField';
import PromoCodeDescription from './PromoCodeDescription';
import PromoCodeSuffixButton from './PromoCodeSuffixButton';

import styles from '../../OrderForm.module.scss';

/**
 * Поле для ввода промокода на странице оформления заявки на сессию
 * */
const OrderFormPromoCodeField = (
  props: IOrderFormPromoCodeField,
): ReactElement => {
  const {
    promoCode,
    onPromoCodeFind,
    isPromoLoading,
    errorPromo,
    setErrorPromo,
    setPromoCode,
    disablePromoCode,
    userId,
    slotId,
    customCreateSession = false,
    isPaymentForm = false,
    setDisablePromoCode,
    disableChange = false,
  } = props;
  const { control, getValues, setValue } = useFormContext<IApplicationForm>();
  const getDisableStatus = () => {
    if (customCreateSession || isPaymentForm) {
      return false;
    }
    return !!(!!promoCode || !userId || disablePromoCode);
  };

  const isDisabled = getDisableStatus();

  return (
    <div className={styles.row}>
      <Typography type="subtitle" className={styles.rowText}>
        ПРОМОКОД
      </Typography>
      <Controller
        control={control}
        name="promoCode"
        render={({ field: { value } }) => (
          <div className={styles.columnBtn}>
            <Tooltip
              placement="topLeft"
              overlayClassName={styles.tooltipContent}
              title={
                !userId && !customCreateSession && !isPaymentForm
                  ? 'Для использования промокода необходимо войти в учетную запись'
                  : ''
              }
            >
              <Input
                value={
                  promoCode?.promo_code ||
                  promoCode?.promoCode ||
                  getValues('promoCode')
                }
                onChange={event => {
                  setValue('promoCode', event?.target?.value);
                }}
                className={styles.customInputHeight}
                size="middle"
                disabled={isDisabled || !!promoCode}
                onPressEnter={() => {
                  if (setDisablePromoCode) setDisablePromoCode(false);
                  onPromoCodeFind({
                    name: String(value),
                    slotId: getValues('slotId'),
                    userId,
                  });
                }}
                suffix={
                  disableChange ? null : (
                    <PromoCodeSuffixButton
                      {...{
                        userId,
                        value: value ?? '',
                        promoCode,
                        setPromoCode,
                        setErrorPromo,
                        isPaymentForm,
                        onPromoCodeFind,
                        isPromoLoading,
                        setDisablePromoCode,
                      }}
                      slotId={slotId}
                      customCreateSession={
                        customCreateSession || isPaymentForm || !disableChange
                      }
                    />
                  )
                }
              />
            </Tooltip>
            <Typography type="description" className={styles.error}>
              {errorPromo}
            </Typography>
          </div>
        )}
      />
      <PromoCodeDescription promoCode={promoCode} />
    </div>
  );
};

export default OrderFormPromoCodeField;
