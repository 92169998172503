import { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { IOption } from '1_shared/config/interfaces/IOption';
import { ISpecialistByIdData } from '1_shared/config/interfaces/ISpecialistByIdData';
import { Content, Faq, Footer, Page } from '1_shared/ui';
import { AboutSpecialist } from '2_entities';
import { Header, SpecialistAchievements, SpecialistCard } from '4_widgets';
import SpecialistPersonCard from '4_widgets/SpecialistPersonCard/ui/SpecialistPersonCard';

import useGetDictionaries from '../../../1_shared/api/dictionary/getDictionaries';
import { GetSpec } from '../../../1_shared/api/services';

import styles from './SpecialistPersonPage.module.scss';
import { EYandexPage } from '../../../1_shared/api/services/configs/interfaces/EYandexConfigs';

const SpecialistPersonPage = (): ReactElement => {
  const { specIdentification } = useParams();
  const { dictionary, dictionaryThemes, dictionaryMethods } =
    useGetDictionaries();
  const { specData, specDataLoading } = GetSpec(specIdentification ?? '');

  const specTemp = useMemo(
    () =>
      ({
        ...specData,
        specialities: dictionary
          ? specData?.specialities.map(
              spec =>
                dictionary?.find((option: IOption) => option.id === spec)
                  ?.label ?? '',
            )
          : [],
        workMethods: dictionaryMethods
          ? specData?.workMethods.map(
              methd =>
                dictionaryMethods?.find(
                  (option: IOption) => option.id === methd,
                )?.label ?? '',
            )
          : [],
        keyThemes: dictionaryThemes
          ? specData?.keyThemes.map(
              themes =>
                dictionaryThemes?.find(
                  (option: IOption) => option.id === themes,
                )?.label ?? '',
            )
          : [],
      }) as ISpecialistByIdData,
    [specData, dictionary, dictionaryMethods, dictionaryThemes],
  );

  const avatar = specData?.mediaContentResponse.find(
    el => el.mediaContentResponse.isPrimary,
  );

  const specCard = useMemo(
    () =>
      ({
        ...specTemp,
        mediaContentResponse: {
          id: avatar?.id,
          ...avatar?.mediaContentResponse,
        },
      }) as any, // ISpecialistData
    [specTemp, specData],
  );

  if (specDataLoading) {
    return (
      <Page>
        <Header />
        <Content>
          <Spin indicator={<LoadingOutlined spin />} />
        </Content>
      </Page>
    );
  }

  return (
    <Page>
      <Header />
      <Content wrapperClass={styles.content}>
        <SpecialistPersonCard spec={specTemp} />
      </Content>
      <Content classNames="bg_lightBlue">
        <SpecialistAchievements spec={specTemp} />
      </Content>
      <AboutSpecialist spec={specTemp} />
      <Content>
        <SpecialistCard spec={specCard} dark pageType={EYandexPage.Spec} />
      </Content>
      <Faq />
      <Footer />
    </Page>
  );
};

export default SpecialistPersonPage;
