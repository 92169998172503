import { Avatar } from 'antd';

import { Typography } from '1_shared/ui';

import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { phoneConvert } from '../../../1_shared/lib/helpers/phoneConvert';
import EditProfileButton from '../../../1_shared/ui/EditProfileButton/EditProfileButton';
import { useAuthContext } from '../../../app/module/lib/hooks/useAuthContext';

import styles from './ProfileUser.module.scss';

const ProfileUser = () => {
  const { user, role } = useAuthContext();
  const isSpec = localStorage.getItem('role') === ERoles.Spec;

  const getAvatar = user?.mediaContentResponse?.find((el: any) => el.isPrimary);

  return (
    <section className={styles.root}>
      {role === ERoles.Spec && (
        <Avatar
          src={
            getAvatar?.mediaContentResponse?.previewLink ||
            getAvatar?.mediaContentResponse?.mainMediaLink ||
            getAvatar?.primaryContent?.previewLink
          }
          size={100}
        />
      )}
      {user?.secondName && user?.firstName && !user?.isAnonymous ? (
        <Typography type="title">{`${user?.firstName} ${user?.secondName}`}</Typography>
      ) : (
        <Typography type="title">{user?.nickname}</Typography>
      )}
      {user?.isAnonymous && <Typography type="title">Аноним</Typography>}
      <div className={styles.wrapInfo}>
        <Typography type="description">{user?.email?.toLowerCase()}</Typography>
        {!!user?.email && !!user?.phone && (
          <Typography type="description">•</Typography>
        )}
        <Typography type="description">
          {phoneConvert(user?.phone || '')}
        </Typography>
      </div>
      {isSpec && <EditProfileButton />}
    </section>
  );
};

export default ProfileUser;
