import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { Spin } from 'antd';

import { Button, Typography } from '../../../../../1_shared/ui';

import {
  IPromoCodeSuffixButton,
  IPromoCodeSuffixButtonProps,
} from './model/IPromoCodeSuffixButton';

import styles from '../../OrderForm.module.scss';

// TODO: need deprecate and use shared button - CustomPromoCodeSuffixButton
const PromoCodeSuffixButton = (
  props: IPromoCodeSuffixButtonProps | IPromoCodeSuffixButton,
): ReactElement => {
  const {
    value,
    slotId,
    promoCode,
    userId,
    onPromoCodeFind,
    isPromoLoading,
    setPromoCode,
    setErrorPromo,
    isPaymentForm,
    customCreateSession,
    setDisablePromoCode,
  } = props;

  const { setValue } = useFormContext();
  const isDisabled = customCreateSession ? false : !value || !slotId || !userId;

  return !promoCode ? (
    <Button
      type="text"
      disabled={isDisabled}
      onClick={() => {
        if (setDisablePromoCode) setDisablePromoCode(false); // используется только для PaymentForm
        onPromoCodeFind({
          name: String(value),
          slotId: slotId ?? '',
          userId,
        });
        setValue('isPromoCodeSubmit', true);
        setErrorPromo(null);
      }}
    >
      <Typography className={styles.codeBtn} type="description">
        {customCreateSession || isPaymentForm
          ? 'ПРИМЕНИТЬ'
          : userId && (!isPromoLoading ? `ПРИМЕНИТЬ` : <Spin />)}
      </Typography>
    </Button>
  ) : (
    <Button
      type="text"
      disabled={isDisabled}
      onClick={() => {
        setPromoCode(undefined);
        setValue('promoCode', '');
        setValue('isPromoCodeSubmit', false);
      }}
    >
      <Typography className={styles.codeBtn} type="description">
        ИЗМЕНИТЬ
      </Typography>
    </Button>
  );
};

export default PromoCodeSuffixButton;
