import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { Button } from '1_shared/ui';

import { getSpecById } from '../../1_shared/api/services/configs/specialistService.config';

import styles from './ClientAppointmentBtn.module.scss';

const ClientAppointmentBtn = (): ReactElement => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const { data: spec } = useSWR(
    user ? `/spec/specialist/${user.id}` : null,
    getSpecById,
  );

  return (
    <div className={styles.container}>
      <Button
        className={styles.appointmentBtn}
        onClick={() => navigate('/pc/create-order')}
        type="primary"
        disabled={!spec?.slots.length}
      >
        ЗАПИСАТЬ КЛИЕНТА
      </Button>
    </div>
  );
};

export default ClientAppointmentBtn;
