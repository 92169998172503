import axios from 'axios';

import { apiLinksByEnv } from '../constants/apiLinksByEnv';

import { ERequestType } from './interfaces/ERequestType';
import { IQueryInputType } from './interfaces/IQueryInputType';

export const specApiInstance = axios.create({
  baseURL: apiLinksByEnv,
  withCredentials: true,
});

const mutation =
  <InputType, OutputType>(requestType: ERequestType = ERequestType.Post) =>
  async (
    url: string,
    { arg }: Readonly<{ arg: InputType }>,
  ): Promise<OutputType> => {
    const controller = (arg as any)?.controller || new AbortController();
    const signal = controller.signal;

    const res = await specApiInstance(url, {
      method: requestType,
      data: arg,
      signal,
    });
    return res?.data;
  };

const query =
  <T, OutputType>(
    input?: Readonly<IQueryInputType<T>> | null,
    options?: Record<string, any>,
  ) =>
  async (url: string): Promise<OutputType> => {
    const controller = (options as any)?.controller || new AbortController();
    const signal = controller.signal;

    const params = input?.requestParams ?? null;
    const urlParams = input?.urlParams?.join('/');
    const res = await specApiInstance.get(
      `${url}${urlParams ? `/${urlParams}` : ''}`,
      {
        ...options,
        params,
        signal,
      },
    );
    return res?.data;
  };

const fetcher = (url: string, init?: RequestInit) =>
  fetch(url, init).then(res => res.json());

export { mutation, query, fetcher };
