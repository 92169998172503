import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import {
  bookClient,
  getSpecById,
  specialistsClients,
} from './configs/specialistService.config';

export const useGetAllUsersBySpec = () => {
  const { data, isLoading } = useSWR(
    `/ss/v2/sessions/approved/specialists-clients`,
    specialistsClients,
  );

  return { data, isLoading };
};

export const useBookClient = () => {
  const { trigger: createSession, data: createdSession } = useSWRMutation(
    `/ss/order/v2/book-client`,
    bookClient,
  );

  return { createSession, createdSession };
};

export const useGetSpec = (specIdentification?: string) => {
  const { data: specData, isLoading: specDataLoading } = useSWR(
    `spec/api/v2/specialist/${specIdentification}`,
    getSpecById,
  );
  return { specData, specDataLoading };
};

export const useGetSpecInfo = (id: string) => {
  const { data: specData, isLoading: specDataLoading } = useSWR(
    `/spec/specialist/${id}`,
    getSpecById,
    { refreshInterval: 1000, revalidateIfStale: true },
  );

  return { specData, specDataLoading };
};
