import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Tooltip } from 'antd';
import useMessage from 'antd/es/message/useMessage';
import axios from 'axios';
import cn from 'classnames';

import useChangeLocalSubscribeStatus from '../../../1_shared/api/hooks/useChangeLocalSubscribeStatus';
import { useSubscriptions } from '../../../1_shared/api/hooks/useNotificationSubcribe';
import { YandexMetricsService } from '../../../1_shared/api/services';
import {
  EYandexInputTypes,
  EYandexTypes,
} from '../../../1_shared/api/services/configs/interfaces/EYandexConfigs';
import { apiLinksByEnv } from '../../../1_shared/constants/apiLinksByEnv';
import { EMessageType } from '../../../1_shared/constants/EMessageType';
import { SpecSubContext } from '../../../1_shared/helpers/SpecSubContext';
import { Button } from '../../../1_shared/ui';
import { isPhoneField } from '../../../4_widgets/LoginForm/lib/checkPhone';

import { ISpecialistNavigateProps } from './interfaces/ISpecialistNavigateProps';

import styles from './SpecialistNavigate.module.scss';
import { getUtm } from '../../../1_shared/helpers/getUtm';

const SpecialistNavigate = ({
  id,
  specId,
  specName,
  className = '',
  dark = false,
  slots,
  handleReserve,
  isSubscribed = false,
  pageType,
}: ISpecialistNavigateProps) => {
  const [messageApi, contextHolder] = useMessage();
  const navigate = useNavigate();
  const { setUserMessageType, setSpecSubName, setIsSubscribed, setSpecId } =
    useContext(SpecSubContext);
  const { subscribeOnSpec, unsubscribeOnSpec } =
    useChangeLocalSubscribeStatus();
  const { subscribe } = useSubscriptions();
  const { requestSlot } = YandexMetricsService();

  const role = localStorage.getItem('role');
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const subscriptions = JSON.parse(
    localStorage.getItem('subscriptions') || '[]',
  );

  const [isHoverButton, setIsHoverButton] = useState<boolean>(false);

  const subscribeDefaultText =
    isSubscribed || (subscriptions?.length && subscriptions?.includes(specId))
      ? 'Вы подписаны'
      : 'Оставить заявку';

  const setContext = (
    contactType: EMessageType | null,
    isSubscribed: boolean,
    specId: string,
    specName?: string,
  ) => {
    setUserMessageType(contactType);
    setSpecSubName(specName ?? '');
    setIsSubscribed(isSubscribed);
    setSpecId(specId);
  };

  const handleSubscribe = async () => {
    /** Yandex.Metrics */
    requestSlot(EYandexInputTypes.Btn, EYandexTypes.Click, pageType, user?.id);
    /** Проверка авторизации пользователя в системе */
    if (!role) {
      setContext(null, true, specId, specName);
      return navigate('#userNotAuthorized');
    }

    const contactType = isPhoneField(user?.phone || '')
      ? EMessageType.PHONE
      : EMessageType.EMAIL;

    /** Отмена подписки на новые слоты у специалиста */
    if (isSubscribed) {
      const unsubResponse = await axios.delete(
        `${apiLinksByEnv}/ss/api/v2/subscribes?specialist=${specId}`,
        { withCredentials: true },
      );
      if (unsubResponse) {
        setContext(contactType, false, specId, specName);
        unsubscribeOnSpec(specId);
        return navigate('#specSubscription');
      }
      messageApi.error({
        content: 'При попытке отписаться от уведомлений произошла ошибка!',
      });
    }

    /** Подписка на новые слоты у пользователя */
    return await subscribe({
      userContactType: contactType,
      subType: 'SUB_AVAILABLE_SLOTS',
      targetSpecId: specId,
      utm: getUtm(),
    }).then(() => {
      /** Yandex.Metrics */
      requestSlot(EYandexInputTypes.Btn, EYandexTypes.Send, pageType, user?.id);

      setContext(contactType, true, specId, specName);
      subscribeOnSpec(specId);
      return navigate('#specSubscription');
    });
  };

  return (
    <div className={cn(styles.root, { [className]: className })}>
      {!!slots?.length && (
        <Button
          className={styles.infoBtn}
          htmlType="submit"
          type="secondary"
          onClick={() => handleReserve()}
          disabled={!slots?.length}
        >
          ЗАПИСАТЬСЯ
        </Button>
      )}
      {(!slots?.length || isSubscribed) && (
        <Tooltip
          placement="bottom"
          title={
            !isSubscribed
              ? 'У специалиста нет доступных слотов'
              : 'Отписаться от уведомлений о новых слотах'
          }
        >
          <Tag
            className={cn(styles.subscribeButton, styles.subscribeButton, {
              [styles.subscribedButton]: isSubscribed,
              [styles.unsubscribeButton]: isHoverButton && isSubscribed,
            })}
            color={isHoverButton && isSubscribed ? 'red' : 'white'}
            onMouseEnter={() => setIsHoverButton(true)}
            onMouseLeave={() => setIsHoverButton(false)}
            onClick={handleSubscribe}
          >
            {isHoverButton && isSubscribed
              ? 'Отписаться'
              : subscribeDefaultText}
          </Tag>
        </Tooltip>
      )}
      {!dark && (
        <Button
          className={styles.infoBtn}
          onClick={() => navigate(`specialist/${id}`)}
        >
          ПОДРОБНЕЕ
        </Button>
      )}
      {contextHolder}
    </div>
  );
};
export default SpecialistNavigate;
