import { TableColumnsType, Tooltip } from 'antd';

import { Button, Typography } from '../../../1_shared/ui';

import { PaymentStatus } from './EPaymentStatus';
import { ISessionsByAdminResponse } from '../../../1_shared/api/services/configs/interfaces/adminService.interface';
import styles from '../ui/AdminSessions.module.scss';
import dayjs from 'dayjs';

const millisecondsInDay = 24 * 60 * 60 * 1000;

const useAdminsSessionsColumnsConfig = (
  cancelOrder: any,
  isOrderCanceling?: boolean,
): {
  adminsSessionsColumnsConfig: (
    isMobileResolution?: boolean,
  ) => TableColumnsType<ISessionsByAdminResponse>;
} => {
  const copyToClipboard = (record: string) => {
    navigator.clipboard.writeText(record || '');
  };

  const adminsSessionsColumnsConfig = (
    isMobileResolution = false,
  ): TableColumnsType<ISessionsByAdminResponse> => [
    {
      title: 'Дата',
      dataIndex: 'start',
      key: 'start',
      render: date => (
        <Typography type={'p'}>
          {dayjs(date).format('L LT').toString()}
        </Typography>
      ),
      align: 'center',
      width: isMobileResolution ? '5px' : '15%',
      fixed: 'left',
    },
    {
      title: 'Специалист',
      dataIndex: 'specName',
      key: 'specName',
      align: 'center',
    },
    {
      title: 'Клиент',
      dataIndex: 'clientNickname',
      key: 'clientNickname',
      align: 'center',
    },
    {
      title: 'Создано',
      dataIndex: 'userCreatedByName',
      key: 'userCreatedByName',
      align: 'center',
    },
    {
      title: 'Стоимость',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Статус оплаты',
      dataIndex: 'confirmationStatus',
      align: 'center',
      width: '15%',
      render: record => <Typography>{PaymentStatus[record]}</Typography>,
    },
    {
      title: 'Ссылка на оплату',
      dataIndex: 'paymentLink',
      align: 'center',
      render: record => (
        <Tooltip title={'Скопировать ссылку на оплату'}>
          <Button
            onClick={() => {
              copyToClipboard(record);
            }}
          >
            Скопировать
          </Button>
        </Tooltip>
      ),
    },
    {
      title: 'Действия',
      align: 'center',
      render: record =>
        dayjs(record.start).diff(dayjs()) > millisecondsInDay ? (
          <Button
            className={styles.btnDanger}
            onClick={() => cancelOrder(record.id)}
          >
            {window.innerWidth <= 1023 ? 'х' : 'Отменить заявку'}
          </Button>
        ) : (
          '-'
        ),
      width: '10%',
    },
  ];

  return { adminsSessionsColumnsConfig };
};

export default useAdminsSessionsColumnsConfig;
