import { env } from '../../env';

export const apiLinksByEnv =
  env.NODE_ENV === 'development'
    ? env.REACT_APP_API_URL_DEV
    : env.REACT_APP_API_URL;

export const apiLinksForNotification =
  env.NODE_ENV === 'development'
    ? env.REACT_APP_API_URL_DEV
    : env.REACT_APP_SOCKET_URL;

export const siteLink =
  env.NODE_ENV === 'development'
    ? env.REACT_APP_DEV_URL
    : env.REACT_APP_PROD_URL;
