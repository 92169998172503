import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BackButton.module.scss';
import clsx from 'clsx';
import { IBackButtonProps } from '../interfaces/IBackButtonProps';

const BackButton = ({
  customClassName,
  content = 'Назад',
  pathTo,
  ...props
}: IBackButtonProps): ReactElement => {
  const navigate = useNavigate();

  return (
    <button
      className={clsx(styles.root, customClassName)}
      {...props}
      onClick={() => navigate(pathTo)}
    >
      {content}
    </button>
  );
};

export default BackButton;
