import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { currentTimeZone } from '../../constants/timeZone';

import { payOrder, specialistsClients } from './configs/clientService.config';

export const useGetOrderForPayment = (orderId: string) => {
  const { data: orderData, isLoading: orderDataLoading } = useSWR(
    `/ss/api/v1/order-read/${orderId}?timezone=${currentTimeZone}`,
    specialistsClients,
  );

  return { orderData, orderDataLoading };
};

export const useProcessPayment = () => {
  const { trigger: payOrderById } = useSWRMutation(
    '/ss/order/v2/process-payment',
    payOrder,
  );

  return { payOrderById };
};