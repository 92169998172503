import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { Input, Typography } from '../../../../1_shared/ui';

import styles from '../OrderForm.module.scss';

/**
 * Поле для отображения ФИО/никнейма пользователя для сессии
 * */
const OrderFormNameField = ({
  disabled,
  name,
}: {
  disabled?: boolean;
  name?: string;
}): ReactElement => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="name"
      render={({ field, fieldState }) => (
        <div className={styles.row}>
          <Typography type="subtitle" className={styles.rowText}>
            ИМЯ ИЛИ ПСЕВДОНИМ
          </Typography>
          <Input
            {...field}
            value={name ?? field.value}
            disabled={disabled}
            className={cn(styles.customInputHeight, {
              [styles.activeInput]: !field?.value,
            })}
            // disabled={!!user?.nickname && user?.nickname !== 'Аноним'}
            size="middle"
            status={fieldState.error ? 'error' : ''}
          />
          {!disabled && (
            <Typography
              type="description"
              className={styles.descriptionFontSize}
            >
              Можете указать псевдоним, если не хотите раскрывать имя. Ваши
              данные строго конфиденциальны
            </Typography>
          )}
        </div>
      )}
    />
  );
};

export default OrderFormNameField;
