import { isBoolean } from 'lodash';
import { z } from 'zod';

import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { isPhoneField } from '../lib/checkPhone';

export const authorizeSchema = z
  .object({
    login: z.custom((value: string) =>
      isPhoneField(value)
        ? z
            .string()
            .regex(
              /\+7\s\d{3}\s\d{3}\s\d{2}\s\d{2}/,
              'Номер должен быть в виде +7 999 999 99 99',
            )
            .min(1, 'Логин обязателен')
            .parse(value, {
              path: ['login'],
            })
        : z
            .string()
            .regex(
              /^[A-Za-z0-9._@-]+$/,
              'Введенная электронная почта не валидна',
            )
            .min(1, 'Логин обязателен')
            .parse(value, {
              path: ['login'],
            }),
    ),
    role: z.string().optional(),
    password: z
      .string()
      .min(6, { message: 'Пароль должен быть не менее 6 символов' })
      .nullable()
      .optional(),
    passwordConfirmation: z
      .string()
      .min(6, { message: 'Пароль должен быть не менее 6 символов' })
      .nullable()
      .optional(),
    code: z
      .string()
      .regex(/^[0-9]{4}$/, 'Код должен содержать 4 цифры')
      .nullable()
      .optional(),

    nickname: z.string().nullable().optional(),
    firstName: z.string().nullable().optional(),
    surname: z.string().nullable().optional(),
    patronymic: z.string().nullable().optional(),
    isReg: z.boolean().nullable().optional(),
  })
  .superRefine(
    (
      {
        role,
        nickname,
        firstName,
        surname,
        password,
        passwordConfirmation,
        isReg,
      },
      ctx,
    ) => {
      if (isBoolean(isReg) && isReg) {
        /** Проверка полей идентификации клиента */
        if (role === ERoles.Client && nickname?.length === 0) {
          ctx.addIssue({
            code: 'custom',
            message: `Поле "Никнейм" обязательное`,
            path: ['nickname'],
          });
        }
        /** Проверка полей идентификации специалиста */
        if (role === ERoles.Spec && firstName?.trim().length === 0) {
          ctx.addIssue({
            code: 'custom',
            message: `Поле "Имя" обязательное`,
            path: ['firstName'],
          });
        }
        if (role === ERoles.Spec && surname?.trim().length === 0) {
          ctx.addIssue({
            code: 'custom',
            message: `Поле "Фамилия" обязательное`,
            path: ['surname'],
          });
        }
        /** Проверка на совпадение пароля в форме регистрации */
        if (
          passwordConfirmation?.length &&
          passwordConfirmation?.length > 1 &&
          password !== passwordConfirmation
        ) {
          ctx.addIssue({
            code: 'custom',
            message: `Пароли должны быть одинаковыми`,
            path: ['passwordConfirmation'],
          });
        }
      }
    },
  );
export type IAuthorizeSchema = z.infer<typeof authorizeSchema>;
