import { ReactElement, useEffect, useLayoutEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { GetSpecInfo } from '../../../../../../1_shared/api/services';
import { ELoginStatus } from '../../../../../../1_shared/config/enums/ELoginStatus';
import { ISlot } from '../../../../../../1_shared/config/interfaces/ISlot';
import { ISpecialistShortData } from '../../../../../../1_shared/config/interfaces/ISpecialistShortData';
import { Typography } from '../../../../../../1_shared/ui';
import { EnterCode } from '../../../../../../3_features';
import { configMedia } from '../../../../../SpecialistCard/ui/config/configMedia';
import usePayment from '../../../../model/usePayment';
import { IApplicationForm } from '../../../interface/IApplicationForm';
import OrderFormAgreements from '../../../OrderFormComponents/OrderFormAgreements';
import OrderFormInformationRow from '../../../OrderFormComponents/OrderFormInformationRow';
import OrderFormLoginField from '../../../OrderFormComponents/OrderFormLoginField';
import OrderFormNameField from '../../../OrderFormComponents/OrderFormNameField';
import OrderFormPromoCodeField from '../../../OrderFormComponents/OrderFormPromoCodeField/OrderFormPromoCodeField';
import OrderFormSpecialistField from '../../../OrderFormComponents/OrderFormSpecialistField';

import { IOrderFormBySlot } from './model/IOrderFormBySlot';

import styles from '../../../OrderForm.module.scss';

/**
 * Компонент формы для оформления клиентом новой заявки или оплаты заявки если она была оформлена ранее
 * */
const OrderFormBySlot = (props: IOrderFormBySlot): ReactElement => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { specData, specDataLoading } = GetSpecInfo((state as any)?.id ?? '');

  const {
    user,
    status,
    slotId,
    updateNickname,
    loginFieldProps,
    disabled, // TODO: [DOT-362] дизейблить все поля кроме промокода
    disablePromoCode = false, // TODO: [DOT-?] для страницы просмотра и отмены сессии (оплаченой/неоплаченной)
  } = props;

  const { isLogin, registerUser, openCode, setOpenCode } = loginFieldProps;

  const { handleSubmit, watch, getValues, setValue } =
    useFormContext<IApplicationForm>();
  watch(['slotId', 'login', 'name', 'promoCode', 'code']);

  const {
    payment,
    onPromoCodeFind,
    promoCode,
    isPromoLoading,
    errorPromo,
    setErrorPromo,
    setPromoCode,
  } = usePayment(false, updateNickname);

  const slot = useMemo(
    () =>
      specData?.slots?.find(slot =>
        slotId
          ? slot.slotId === slotId
          : slot.slotId === (state as any)?.slot?.slotId ||
            specData?.slots[0]?.slotId,
      ),
    [slotId, specData, state],
  );

  const price = useMemo(
    () =>
      specData?.sessionsInfo.find(info => info.sessionType === slot?.kind)
        ?.price ?? 0,
    [slot?.kind, specData?.sessionsInfo],
  );
  const avatar = useMemo(
    () =>
      specData?.mediaContentResponse.find(el => el.isPrimary)
        ?.mediaContentResponse.previewLink ??
      specData?.mediaContentResponse[0]?.mediaContentResponse.previewLink ??
      configMedia.mediaContentResponse.previewLink,
    [specData],
  );

  const onSubmit = async (data: IApplicationForm) => {
    // Удаление данных о ранее заполненной анкете из LS
    localStorage.removeItem('userByAnketa');

    await payment(
      Number(promoCode?.basic_price ?? price),
      data,
      specData as ISpecialistShortData,
      slot as ISlot,
    );
  };

  useLayoutEffect(() => {
    /** Редирект на каталог специалистов если данные не актуальны */
    if (specDataLoading) return;
    if ((!price || !slot) && !!specData?.latinId) {
      navigate(`/specialists/specialist/${specData?.latinId}`);
    }

    if (!specData?.id) {
      navigate(`/specialists`);
    }
  }, [specDataLoading]);

  useEffect(() => {
    const login = getValues('login');
    if (status === ELoginStatus.NotFound && !!login) {
      (async () => await registerUser(login))();
    }
  }, [status]);

  useEffect(() => {
    const promoCodeString = promoCode?.promoCode || promoCode?.promo_code;
    if (promoCodeString) setValue('promoCode', promoCodeString);
  }, [promoCode]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.formWrapper}>
        <div onSubmit={handleSubmit(onSubmit)} className={styles.formGrid}>
          <OrderFormSpecialistField
            isLoading={specDataLoading}
            avatar={avatar}
            spec={specData}
            setPromoCode={setPromoCode}
            disabled={disabled}
          />
          {!user?.userId && (
            <OrderFormLoginField
              {...loginFieldProps}
              status={status}
              user={user}
              disabled={disabled}
            />
          )}
          {(user || (!isLogin && status === ELoginStatus.CodeSended)) && (
            <OrderFormNameField disabled={disabled} />
          )}
          <OrderFormPromoCodeField
            {...{
              promoCode,
              onPromoCodeFind,
              isPromoLoading,
              errorPromo,
              setPromoCode,
              setErrorPromo,
              disablePromoCode,
              userId: user?.userId ?? '',
              slotId,
            }}
            disableChange={false}
          />
          <OrderFormAgreements />
        </div>
        {price ? (
          <OrderFormInformationRow<IApplicationForm>
            {...{ user, promoCode, slotId, status, price, disabled }}
          />
        ) : (
          <Typography type="textM" className={styles.error}>
            У специалиста не указана цена для слота.
          </Typography>
        )}
      </form>
      {!disabled && (
        <EnterCode
          name="code"
          {...loginFieldProps}
          onClose={() => {
            if (setOpenCode) {
              setOpenCode(!openCode);
            }
            setValue('code', undefined);
          }}
        />
      )}
    </>
  );
};

export default OrderFormBySlot;
