import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { GetOrderForPayment } from '../../../../../../1_shared/api/services';
import { ISlot } from '../../../../../../1_shared/config/interfaces/ISlot';
import { ISpecialistShortData } from '../../../../../../1_shared/config/interfaces/ISpecialistShortData';
import usePayment from '../../../../model/usePayment';
import { IApplicationForm, IPaymentOrder } from '../../../interface/IApplicationForm';
import OrderFormAgreements from '../../../OrderFormComponents/OrderFormAgreements';
import OrderFormInformationRow from '../../../OrderFormComponents/OrderFormInformationRow';
import OrderFormNameField from '../../../OrderFormComponents/OrderFormNameField';
import OrderFormPromoCodeField from '../../../OrderFormComponents/OrderFormPromoCodeField/OrderFormPromoCodeField';
import OrderFormSpecialistSimpleField
  from '../../../OrderFormComponents/OrderFormSpecialistField/OrderFormSpecialistSimpleField';

import { IOrderFormBySlot, IOrderFormSimpleSlot } from './model/IOrderFormBySlot';

import styles from '../../../OrderForm.module.scss';

/**
 * Компонент формы для оформления клиентом новой заявки или оплаты заявки если она была оформлена ранее
 * TODO: JSDoc
 * */
const OrderFormPaymentOrder = (
  props: IOrderFormBySlot | IOrderFormSimpleSlot, // TODO: simplify
): ReactElement => {
  const params = useParams();
  const {
    user,
    status,
    slotId,
    disabled, // дизейблит все поля кроме промокода
  } = props;

  const [disablePromoCode, setDisablePromoCode] = useState(true);

  const { orderData, orderDataLoading } = GetOrderForPayment(
    params?.orderId ?? '',
  );

  const { getValues, setValue } = useFormContext<IApplicationForm>();

  const {
    payment,
    onPromoCodeFind,
    promoCode: promoCodeObject,
    isPromoLoading,
    errorPromo,
    setErrorPromo,
    setPromoCode,
  } = usePayment(true);

  const promoCode = useMemo(
    () =>
      disablePromoCode && orderData?.promoCode
        ? {
            promo_code: orderData?.promoCode,
            promoCode: orderData?.promoCode,
            discount: orderData?.discount,
            discounted_price: orderData?.discountedPrice,
            basic_price: orderData?.price,
            price: orderData?.price,
          }
        : promoCodeObject,
    [disablePromoCode, orderData, promoCodeObject],
  );

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const price = orderData?.price ?? 0;
    const discount = orderData?.discount || promoCode?.discount || 0;
    const discountedPrice = price - discount;

    const data: IPaymentOrder = {
      ...getValues(),
      id: orderData?.id,
      orderId: orderData?.id ?? '',
      slotId: orderData?.slotId ?? '',
      promoCode:
        orderData?.promoCode || promoCode?.promoCode || getValues('promoCode'),
      discount,
      discountedPrice,
      price,
    };

    await payment(
      Number(promoCode?.basic_price ?? orderData?.price),
      data,
      orderData?.specialist as unknown as ISpecialistShortData,
      { start: orderData?.timePoint, slotId: orderData?.slotId } as ISlot,
    );
  };

  useEffect(() => {
    if (orderData?.promoCode) {
      setDisablePromoCode(!!orderData?.promoCode);
      setValue('promoCode', orderData?.promoCode);
    }
  }, [orderData?.promoCode]);

  return (
    <form onSubmit={event => onSubmit(event)} className={styles.formWrapper}>
      <div className={cn(styles.formGrid, styles.gap30)}>
        <OrderFormSpecialistSimpleField
          orderData={orderData}
          orderDataLoading={orderDataLoading}
        />
        <OrderFormNameField
          disabled={disabled}
          name={orderData?.clientResponse.nickname ?? undefined}
        />
        <OrderFormPromoCodeField
          {...{
            promoCode,
            disablePromoCode,
            setDisablePromoCode,
            onPromoCodeFind,
            isPromoLoading,
            errorPromo,
            setErrorPromo,
            setPromoCode,
            userId: orderData?.clientResponse.userId ?? '',
            slotId: orderData?.slotId ?? '',
          }}
          isPaymentForm
          disableChange={disablePromoCode && !!promoCode}
        />
        <OrderFormAgreements />
      </div>
      <OrderFormInformationRow
        {...{
          user,
          promoCode,
          slotId: slotId ?? orderData?.slotId,
          status,
          price: orderData?.price ?? 0,
        }}
        isPaymentForm
      />
    </form>
  );
};

export default OrderFormPaymentOrder;
