import { Controller, FieldValues, useFormContext } from 'react-hook-form';

import { Input, Typography } from '../index';

import CustomPromoCodeSuffixButton from './ui/CustomPromoCodeSuffixButton';

import styles from './CustomInputPromoCode.module.scss';
import { ICustomInputPromoCode } from './model/customInputPromoCode.interface';

const CustomInputPromoCode = <T extends FieldValues>(
  props: ICustomInputPromoCode<T>,
) => {
  const { control, getValues } = useFormContext();
  const {
    name,
    promoCode,
    isDisabled,
    onPromoCodeFind,
    userId,
    setPromoCode,
    isPromoLoading,
    setDisablePromoCode,
    slotId,
    errorPromo,
    className,
    customCreateSession,
    setErrorPromo,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className={className}>
          <Input
            {...field}
            defaultValue={
              promoCode?.promo_code || promoCode?.promoCode || getValues(name)
            }
            size="middle"
            disabled={isDisabled}
            onPressEnter={() =>
              onPromoCodeFind({
                name: String(field.value),
                slotId,
                userId,
              })
            }
            suffix={
              <CustomPromoCodeSuffixButton
                {...{
                  value: field.value,
                  userId,
                  field,
                  promoCode,
                  setPromoCode,
                  onPromoCodeFind,
                  isPromoLoading,
                  setDisablePromoCode,
                  slotId,
                  setErrorPromo,
                }}
                customCreateSession={customCreateSession}
              />
            }
          />
          <Typography type="description" className={styles.error}>
            {errorPromo}
          </Typography>
        </div>
      )}
    />
  );
};

export default CustomInputPromoCode;
