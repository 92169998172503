import { ESlotStatus } from '../../../../1_shared/config/enums/ESlotStatus';

export const PaymentStatusText: Record<string, string> = {
  [ESlotStatus.Approved]: 'Оплачено',
  [ESlotStatus.WaitingForPay]: 'Оплатить',
};

export const PaymentStatusTextForSpecs: Record<string, string> = {
  [ESlotStatus.Approved]: 'Оплачено',
  [ESlotStatus.WaitingForPay]: 'Не оплачено',
};
