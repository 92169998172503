import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SWRConfig } from 'swr';

import themeConfig from '../1_shared/lib/theme';
import { env } from '../env';

import PrivateRouter from './module/provider/PrivateRouter';

import 'app/styles/globals.scss';

import 'dayjs/locale/ru';
import { StrictMode } from 'react';

dayjs.locale('ru');
dayjs.extend(customParseFormat);

const App = () => (
  <ConfigProvider locale={ruRU} theme={themeConfig}>
    <SWRConfig value={{ provider: () => new Map() }}>
      <main className="main">
        <StrictMode>
          <PrivateRouter />
          <div className="version">v {env.APP_VERSION}</div>
        </StrictMode>
      </main>
    </SWRConfig>
  </ConfigProvider>
);
export default App;
