import { Suspense, useEffect, useMemo } from 'react';
import { Route, Routes as RoutesPath, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import useInterceptors from '../../../1_shared/api/hooks/useInterceptors';
import { ERoles } from '../../../1_shared/config/enums/ERoles';
import { RoutePath } from '../../../1_shared/config/routes';
import {
  createLocationString,
  getParamsUtm,
  getParamsWithoutUtm,
} from '../../../1_shared/helpers/locationHelpers';
import separateParams from '../../../1_shared/helpers/separateParams';
import { routerConfig } from '../../config/router';
import NotificationContext from '../lib/context/NotificationContext';

const Routes = () => {
  const { contextHolder, messageApi } = useInterceptors();

  // UTM-functional
  const location = useLocation();
  const navigate = useNavigate();

  const role = (localStorage.getItem('role') || ERoles.Unauthorized) as ERoles;

  useEffect(() => {
    if (
      role === ERoles.Unauthorized &&
      (location.pathname === RoutePath.CLIENT_CABINET ||
        location.pathname === RoutePath.SPECIALIST_CABINET)
    ) {
      navigate(RoutePath.LOGIN);
    }

    if (location.search.includes('utm')) {
      const utmParams = getParamsUtm(separateParams(location.search));
      const parameters = createLocationString(
        getParamsWithoutUtm(separateParams(location.search)),
      );

      localStorage.setItem(
        'utm',
        JSON.stringify({ ...utmParams, queryParams: location.search }),
      );
      navigate(`${location.pathname}?${parameters}`);
    }
  }, [location, navigate]);

  const router = useMemo(
    () =>
      routerConfig
        .filter(
          route =>
            route.roles.includes(role) ||
            route.roles.includes(ERoles.Unauthorized),
        )
        .map(route => ({
          path: route.path,
          element: route.element,
        })),
    [role],
  );

  return (
    <section>
      <NotificationContext.Provider value={{ messageApi }}>
        <RoutesPath>
          {router.map(route => (
            <Route
              key={route.path}
              path={route.path}
              element={<Suspense fallback={<Spin />}>{route.element}</Suspense>}
            />
          ))}
        </RoutesPath>
        {contextHolder}
      </NotificationContext.Provider>
    </section>
  );
};

export default Routes;
