import { ReactElement } from 'react';
import cn from 'classnames';

import IContentProps from './interfaces/IContentProps';

import styles from './Content.module.scss';

const Content = ({
  children,
  classNames: currentClassName,
  wrapperClass = '',
}: IContentProps): ReactElement => {
  const className = cn(
    {
      [styles.content]: currentClassName !== 'bg_lightBlue',
      [styles.bg_lightBlue]: currentClassName === 'bg_lightBlue',
      [styles.paddingTopNone]: wrapperClass.includes('paddingTopNone'),
      [styles.paddingXNone]: wrapperClass.includes('paddingXNone'),
    },
    currentClassName,
  );

  const wrapperClassname = cn(styles.contentContainer, wrapperClass, {
    [styles.containerFullWidth]: wrapperClass.includes('containerFullWidth'),
  });
  console.log(wrapperClassname);

  return (
    <div className={className}>
      <div className={wrapperClassname}>{children}</div>
    </div>
  );
};

export default Content;
