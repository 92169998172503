import { mutation, query } from '../../apiInstance';

import {
  IOrderForPaymentResponse,
  IOrderPaymentRequest,
  IOrderPaymentResponse,
} from './interfaces/clientService.interface';

export const specialistsClients = query<null, IOrderForPaymentResponse>();

export const payOrder = mutation<IOrderPaymentRequest, IOrderPaymentResponse>();
