import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import {
  cancelSessionByAdmin,
  clientByInfo,
  createSessionByAdmin,
  sessionsByAdmin,
  slotsBySpecId,
  specsByName,
  updateUserInfoByAdmin,
} from './configs/adminService.config';
import {
  RCancelSessionByAdmin,
  RCreateSessionByAdmin,
  RGetAllSessionsByAdmin,
  RGetClientInfo,
  RGetSpecByName,
  RGetSpecInfo,
  RUpdateUserInfoByAdmin,
} from './configs/routes/adminRoutes.config';

export const useGetSpecByName = (name: string) => {
  const { data, isLoading } = useSWR(
    !name ? null : `${RGetSpecByName}${name}`,
    specsByName,
  );
  const specs = (data ?? [])?.map(spec => ({
    label: `${spec.firstName} ${spec.lastName}`,
    value: spec.userId,
  }));

  return { specs, specsLoading: isLoading };
};

export const useGetSpecInfo = (specId: string) => {
  const { data, isLoading } = useSWR(
    !specId ? null : `${RGetSpecInfo}${specId}`,
    slotsBySpecId,
  );

  return { slots: data, slotsLoading: isLoading };
};

export const useGetClientInfo = (info: string) => {
  const { data, isLoading } = useSWR(
    !info ? null : `${RGetClientInfo}${info}`,
    clientByInfo,
  );

  const clients = (data ?? [])?.map(client => ({
    label: client?.nickname || client?.phone || client?.email,
    phone: client?.phone,
    email: client?.email,
    value: client.id,
  }));

  return { clients, clientsLoading: isLoading };
};

export const useCreateSessionByAdmin = () => {
  const { data, trigger, isMutating } = useSWRMutation(
    RCreateSessionByAdmin,
    createSessionByAdmin,
  );

  return { response: data, createSession: trigger, loading: isMutating };
};

export const useGetAllSessionsByAdmin = () => {
  const { data, isLoading, mutate } = useSWR(
    RGetAllSessionsByAdmin,
    sessionsByAdmin,
    {
      revalidateIfStale: true,
      dedupingInterval: 10000,
    },
  );

  return { sessions: data, isSessionsLoading: isLoading, mutate };
};

export const useCancelSessionByAdmin = () => {
  const { trigger, isMutating } = useSWRMutation(
    RCancelSessionByAdmin,
    cancelSessionByAdmin,
  );

  return { cancelOrder: trigger, isOrderCanceling: isMutating };
};

export const useUpdateUserInfoByAdmin = () => {
  const { trigger, isMutating } = useSWRMutation(
    RUpdateUserInfoByAdmin,
    updateUserInfoByAdmin,
  );

  return { updateUser: trigger, updatingUser: isMutating };
};
