import { ERoles } from '../config/enums/ERoles';

export const getProfileUrl = (role: ERoles) => {
  switch (role) {
    case ERoles.Spec:
      return '/spec/specialist/profile';
    case ERoles.Client:
    case ERoles.Admin:
    default:
      return '/aim/v1/user/profile';
  }
};
