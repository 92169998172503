import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { Spin } from 'antd';

import { IPromoCodeSuffixButton } from '../../../../4_widgets/OrderForm/ui/OrderFormComponents/OrderFormPromoCodeField/model/IPromoCodeSuffixButton';
import { Button, Typography } from '../../index';

import styles from '../CustomInputPromoCode.module.scss';

const CustomPromoCodeSuffixButton = (
  props: IPromoCodeSuffixButton,
): ReactElement => {
  const {
    field,
    slotId,
    promoCode,
    userId,
    onPromoCodeFind,
    isPromoLoading,
    setPromoCode,
    customCreateSession,
    setDisablePromoCode,
  } = props;

  const { setValue } = useFormContext();
  const isDisabled = customCreateSession
    ? false
    : !field.value || !slotId || !userId;

  return !promoCode ? (
    <Button
      type="text"
      disabled={isDisabled}
      onClick={() => {
        onPromoCodeFind({
          name: String(field.value),
          slotId,
          userId,
        });
        setValue('isPromoCodeSubmit', true);
      }}
    >
      <Typography className={styles.codeBtn} type="description">
        {customCreateSession
          ? 'ПРИМЕНИТЬ'
          : userId && (!isPromoLoading ? `ПРИМЕНИТЬ` : <Spin />)}
      </Typography>
    </Button>
  ) : (
    <Button
      type="text"
      disabled={isDisabled}
      onClick={() => {
        // TODO: передавать конкретное действие в качестве функции-пропса
        setPromoCode(undefined);
        field.onChange('');
        setValue('isPromoCodeSubmit', false);
        if (setDisablePromoCode) setDisablePromoCode(false);
      }}
    >
      <Typography className={styles.codeBtn} type="description">
        ИЗМЕНИТЬ
      </Typography>
    </Button>
  );
};

export default CustomPromoCodeSuffixButton;
