import { ReactElement } from 'react';

import { Content, Footer, Page } from '1_shared/ui';
import { Header, OrderForm } from '4_widgets';

import { EOrderFormType } from '../../../4_widgets/OrderForm/ui/constants/EOrderFormType';

const OrderPage = ({
  orderFormType = EOrderFormType.SESSION_ORDER,
}: {
  orderFormType: EOrderFormType;
}): ReactElement => (
  <Page>
    <Header />
    <Content>
      <OrderForm orderFormType={orderFormType} />
    </Content>
    <Footer />
  </Page>
);

export default OrderPage;
